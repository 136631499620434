import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';

export function start(app, pc) {
	// NOTE: THIS SCRIPT ONLY WORKS IF 'PRESERVE DRAWING BUFFER' IS ENABLED IN PROJECT SETTINGS

	let Screenshot = pc.createScript('screenshot');

	Screenshot.prototype.initialize = function () {
		this._triggerScreenshot = false;
		this._triggerMultipleScreenshots = false;
		this._triggerPuppeteerScreenshots = false;
		this._window = null;
		this._puppeteer = false;

		this.screenShotAngles = [
			{ angle: 0, pitch: -25, id: Canvas3D.SCREENSHOT_FRONTVIEW },
			{ angle: 90, pitch: -25, id: Canvas3D.SCREENSHOT_RIGHTVIEW },
			{ angle: 180, pitch: -25, id: Canvas3D.SCREENSHOT_REARVIEW },
			{ angle: 270, pitch: -25, id: Canvas3D.SCREENSHOT_LEFTVIEW },
			{ angle: 0, pitch: -90, id: Canvas3D.SCREENSHOT_TOPVIEW },
		];
		this.drawings = [];

		this.currentMultipleScreenshot = -1;
		this.app.on(
			'ui:takeScreenshot',
			function () {
				// Hufterproof
				if (this._triggerScreenshot === false) {
					Canvas3D.CURRENT.app.autoRender = true;
					this._triggerScreenshot = true;
				}
			},
			this,
		);

		this.app.on(
			'ui:takeMultipleScreenshots',
			function () {
				if (this._triggerMultipleScreenshots === false) {
					this._puppeteer = false;
					this.drawings = [];

					Canvas3D.CURRENT.app.autoRender = true;
					let orbitCamera = this.app.root.children.find((item) => item.name === 'orbitcamera');
					orbitCamera.c.script.orbitCamera.pivotPoint = new pc.Vec3(Configuration.CURRENT.etages.floor.width / 1000 / 2, 0.5, Configuration.CURRENT.etages.floor.length / 1000 / 2);
					this._triggerMultipleScreenshots = true;
					Configuration.CURRENT.takingScreenshot = true;
				}
			},
			this,
		);
		this.app.on(
			'ui:pupperteerTakeScreenshots',
			function () {
				this._puppeteer = true;
				this.drawings = [];

				if (this._triggerMultipleScreenshots === false) {
					Canvas3D.CURRENT.app.autoRender = true;
					let orbitCamera = this.app.root.children.find((item) => item.name === 'orbitcamera');
					orbitCamera.c.script.orbitCamera.pivotPoint = new pc.Vec3(Configuration.CURRENT.etages.floor.width / 1000 / 2, 0.5, Configuration.CURRENT.etages.floor.length / 1000 / 2);
					this._triggerMultipleScreenshots = true;
					Configuration.CURRENT.takingScreenshot = true;
				}
			},
			this,
		);
		this.app.on('postrender', this.postRender, this);
	};

	Screenshot.prototype.takeScreenshot = function () {
		let canvas = this.app.graphicsDevice.canvas;

		//3d images als jpeg sturen in tegenstelling tot overige die als png kleiner zijn
		let dataImage = canvas.toDataURL('image/jpeg');
		if (this.currentMultipleScreenshot === -1) {
			this.drawings.push({ type: this._indexScreenshot, floor: 0, drawing: dataImage });
			if (!this._puppeteer) {
				Canvas3D.CURRENT.saveScreenshotERP(dataImage);
			}
		} else {
			this.drawings.push({ type: this._indexScreenshot, floor: 0, drawing: dataImage });
			// Als hoeveelheid fotos opgeslagen gelijk is aan de hoeveelheid angles, dan terugsturen
			if (this.drawings.length === this.screenShotAngles.length) {
				if (this._puppeteer) {
					Canvas3D.CURRENT.pictures3D = this.drawings;
				} else {
					Canvas3D.CURRENT.saveScreenshotERP(this.drawings);
				}
			}
		}

		// Als nog niet de laatste, trigger volgende screenshot
		if (this.currentMultipleScreenshot !== -1 && this.currentMultipleScreenshot < this.screenShotAngles.length - 1) {
			this.currentMultipleScreenshot++;
			this.takeMultipleScreenshots();
		}
		// Alleen resetten wanneer het multiple screenshots is, anders gewoon de camerapositie laten staan.
		else if (this.currentMultipleScreenshot !== -1) {
			// Helemaal klaar, reset de camera
			this.currentMultipleScreenshot = -1;
			let orbitCamera = this.app.root.children.find((item) => item.name === 'orbitcamera');
			orbitCamera.c.script.orbitCamera.yaw = 0;
			orbitCamera.c.script.orbitCamera.pitch = -25;
		}
	};

	Screenshot.prototype.takeMultipleScreenshots = function () {
		// Orbit vinden en draaien vanaf middenpunt.
		let orbitCamera = this.app.root.children.find((item) => item.name === 'orbitcamera');
		orbitCamera.c.script.orbitCamera.yaw = this.screenShotAngles[this.currentMultipleScreenshot].angle;
		orbitCamera.c.script.orbitCamera.pitch = this.screenShotAngles[this.currentMultipleScreenshot].pitch;
		this.app.graphicsDevice.gl.finish();
		setTimeout(() => {
			this._indexScreenshot = this.screenShotAngles[this.currentMultipleScreenshot].id;
			this._triggerScreenshot = true;
		}, 1500);
	};

	Screenshot.prototype.postRender = function () {
		if (this._triggerScreenshot) {
			this.takeScreenshot();
			this._triggerScreenshot = false;
			this._indexScreenshot = 0;
		}
		if (this._triggerMultipleScreenshots) {
			this.currentMultipleScreenshot = 0;
			this.takeMultipleScreenshots();
			this._triggerMultipleScreenshots = false;
		}
	};
}
