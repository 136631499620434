import { Canvas } from '../../draw/canvas';
import { Circle } from '../../draw/circle';
import { DrawValue } from '../../draw/drawValue';
import { Line } from '../../draw/line';
import { ObjectGroup } from '../../draw/objectGroup';
import { SizeHandle } from '../../draw/sizeHandle';
import { Mathematic } from '../../helpers/mathematic';
import { Statics } from '../../helpers/statics';
import { Configuration } from '../configuration';
import { RemoveRaster } from '../removeRaster';
import { Stair } from '../stair';
import { StairOutSide } from '../stairOutSide';
import { Stairs } from '../stairs';
import { CageLadder } from './cageLadder';

export class CageLadderOutSide extends CageLadder {
	objectName = 'CageLadderOutSide';
	place = Stair.PLACE_OUTSIDE;

	get dimensionsHorizontalSizeHandle() {
		if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
			return [Math.round(this.x), this.getWidth(), Math.round(Configuration.CURRENT.raster.getSizeX() - this.x - this.getWidth())];
		} else if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			return [];
		}
	}
	get dimensionsVerticalSizeHandle() {
		if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			return [Math.round(this.y), this.getDepth(), Math.round(Configuration.CURRENT.raster.getSizeY() - this.y - this.getDepth())];
		} else if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
			return [];
		}
	}
	constructor(newCageLadderConfiguration, drawObject, cageLadder) {
		super(newCageLadderConfiguration, cageLadder);

		if (drawObject !== null && typeof drawObject !== 'undefined') {
			// Opslaan aan welke kant van de vloer de cageLadder komt te staan.
			// Dit komt uit het drawObject van de possiblePositions, daar hebben we toen een positie aan toegevoegd.
			this.position = drawObject.objectParams.position;

			// Onthouden of de ladder aan bovenkant/onderkant of aan de linker/rechterkant staat.
			const indexX = drawObject.objectParams.x;
			const indexY = drawObject.objectParams.y;
			const overhang = Configuration.CURRENT.overhang.size;

			const startClickedRasterX = Configuration.CURRENT.raster.getSizeX(drawObject.objectParams.x - 1);
			const startClickedRasterY = Configuration.CURRENT.raster.getSizeY(drawObject.objectParams.y - 1);
			const endClickedRasterX = Configuration.CURRENT.raster.getSizeX(drawObject.objectParams.x);
			const endClickedRasterY = Configuration.CURRENT.raster.getSizeY(drawObject.objectParams.y);

			// Calculate the X coordinate.
			// X berekenen, wanneer het een top of bottom, de ladder in het midden van gekozen raster zetten.
			// Zo niet dan X = eindpunt gekozen raster.

			switch (this.position) {
				case Mathematic.TOP:
					// Midden van raster op X as voor X positie.
					// Voor Y dan top geklikte raster - overhang.
					this.x = startClickedRasterX + (endClickedRasterX - startClickedRasterX) / 2 - this.getWidth() / 2;
					this.y = Configuration.CURRENT.raster.getSizeY(indexY - 1) - overhang;
					break;
				case Mathematic.RIGHT:
					// Midden van raster op Y as voor Y positie.
					// Voor x dan eind eigen raster + overhang.
					this.y = startClickedRasterY + (endClickedRasterY - startClickedRasterY) / 2 - this.getDepth() / 2;
					this.x = Configuration.CURRENT.raster.getSizeX(indexX) + overhang;
					break;
				case Mathematic.BOTTOM:
					// Midden van raster op X as voor positie X.
					// Voor Y dan eind geklikte raster + overhang.
					this.x = startClickedRasterX + (endClickedRasterX - startClickedRasterX) / 2 - this.getWidth() / 2;
					this.y = Configuration.CURRENT.raster.getSizeY(indexY) + overhang;
					break;
				case Mathematic.LEFT:
					// Midden van raster op Y as.
					this.y = startClickedRasterY + (endClickedRasterY - startClickedRasterY) / 2 - this.getDepth() / 2;
					this.x = Configuration.CURRENT.raster.getSizeX(indexX - 1) - overhang;
					break;
			}
		}
	}

	// !! POSSIBLE POSITION LOGIC AND CLICK FUNCTIONALLITY.
	addPossiblePositions() {
		let startX = 0;
		let topActiveEtageUnder = false;
		let rightActiveEtageUnder = false;
		let bottomActiveEtageUnder = false;
		let leftActiveEtageUnder = false;

		const activeEtage = Configuration.CURRENT.etages.activeEtage();
		const etageUnder = Configuration.CURRENT.etages.get(Configuration.CURRENT.etages.activeEtageIndex - 1);
		let checkEtageUnder = true;

		// Wanneer etage onder huidige er niet is, dan altijd op eerste verdieping, dan mag je altijd aan alle kanten van de vloer tekenen.
		// Mits op huidige etage de checks zijn gedaan voor rasters ernaast boven en onder.
		if (etageUnder === null || typeof etageUnder === 'undefined') {
			topActiveEtageUnder = true;
			rightActiveEtageUnder = true;
			bottomActiveEtageUnder = true;
			leftActiveEtageUnder = true;
			checkEtageUnder = false;
		}

		Configuration.CURRENT.raster.spansX.getSpans().forEach((spanX, indexX) => {
			let startY = 0;
			Configuration.CURRENT.raster.spansY.getSpans().forEach((spanY, indexY) => {
				// Alleen wanneer raster actief is possible position tekenen.
				if (activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY))) {
					// check of nog niet cageLadder tegen de vloer bevat
					let topActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY - 1));
					let rightActive = activeEtage.isActiveRaster(new RemoveRaster(indexX + 1, indexY));
					let bottomActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY + 1));
					let leftActive = activeEtage.isActiveRaster(new RemoveRaster(indexX - 1, indexY));
					checkEtageUnder = false;
					// Voor nu deze code niet nodig, daarom hierboven op false.
					// Nu dus wel mogelijk om te plaatsen op plek waar het niet goed uitkomt.
					if (checkEtageUnder === true) {
						topActiveEtageUnder = etageUnder.isActiveRaster(new RemoveRaster(indexX, indexY - 1));
						rightActiveEtageUnder = etageUnder.isActiveRaster(new RemoveRaster(indexX + 1, indexY));
						bottomActiveEtageUnder = etageUnder.isActiveRaster(new RemoveRaster(indexX, indexY + 1));
						leftActiveEtageUnder = etageUnder.isActiveRaster(new RemoveRaster(indexX - 1, indexY));

						if (topActive === false && topActiveEtageUnder === true) {
							// Bij toevoegen moet het raster op huidige etage erboven niet actief zijn, maar etage eronder daarboven natuurlijk wel.
							this.addPossiblePositionLine(startX, startY, startX + spanX.value, startY, indexX, indexY, Mathematic.TOP);
						}
						if (rightActive === false && rightActiveEtageUnder === true) {
							this.addPossiblePositionLine(startX + spanX.value, startY, startX + spanX.value, startY + spanY.value, indexX, indexY, Mathematic.RIGHT);
						}
						if (bottomActive === false && bottomActiveEtageUnder === true) {
							this.addPossiblePositionLine(startX, startY + spanY.value, startX + spanX.value, startY + spanY.value, indexX, indexY, Mathematic.BOTTOM);
						}
						if (leftActive === false && leftActiveEtageUnder === true) {
							this.addPossiblePositionLine(startX, startY, startX, startY + spanY.value, indexX, indexY, Mathematic.LEFT);
						}
					} else {
						if (topActive === false) {
							this.addPossiblePositionLine(startX, startY, startX + spanX.value, startY, indexX, indexY, Mathematic.TOP);
						}
						if (rightActive === false) {
							this.addPossiblePositionLine(startX + spanX.value, startY, startX + spanX.value, startY + spanY.value, indexX, indexY, Mathematic.RIGHT);
						}
						if (bottomActive === false) {
							this.addPossiblePositionLine(startX, startY + spanY.value, startX + spanX.value, startY + spanY.value, indexX, indexY, Mathematic.BOTTOM);
						}
						if (leftActive === false) {
							this.addPossiblePositionLine(startX, startY, startX, startY + spanY.value, indexX, indexY, Mathematic.LEFT);
						}
					}
				}
				startY += spanY.value;
			});
			startX += spanX.value;
		});
	}
	addPossiblePositionLine(startX, startY, endX, endY, indexX, indexY, position) {
		Canvas.CURRENT.addDrawObject(
			new Line(
				new DrawValue(startX),
				new DrawValue(startY),
				new DrawValue(endX),
				new DrawValue(endY),
				new DrawValue(0, 3),
				Stairs.COLORS.lineDash,
				Stairs.COLORS.possiblePositionOutSide,
				null,
				null,
				true,
				Configuration.CURRENT.cageLadders,
				{
					x: indexX,
					y: indexY,
					position: position,
					place: this.place,
				},
			),
		);
	}

	// !! MOUSE OVER POSSIBLE POSITION AND LEAVE POSSIBLE POSITION.
	onMouseMovePossiblePosition(evt, drawObject) {
		drawObject.lineColor = Stairs.COLORS.possiblePositionOutSideSelected;
		return { stopPropagation: true };
	}
	onMouseLeavePossiblePosition(evt, drawObject) {
		drawObject.lineColor = Stairs.COLORS.possiblePositionOutSide;
		return { stopPropagation: true };
	}

	// !! RASTERCHANGED FUNCTIONALITY.
	onRasterChanged() {
		this.updatePosition();
		this.updateRasters();
		this.setBoundaries();
		this.active = this.rastersActive();
	}
	updatePosition() {
		const raster = this.rasters[0];
		if (raster === null || typeof raster === 'undefined') {
			return;
		}
		const rasterStartX = Configuration.CURRENT.raster.getSizeX(raster.x - 1);
		const rasterEndX = Configuration.CURRENT.raster.getSizeX(raster.x);
		const rasterStartY = Configuration.CURRENT.raster.getSizeY(raster.y - 1);
		const rasterEndY = Configuration.CURRENT.raster.getSizeY(raster.y);
		const totalLengthRasterX = Configuration.CURRENT.raster.getSizeX();
		const totalLengthRasterY = Configuration.CURRENT.raster.getSizeY();
		const overhang = Configuration.CURRENT.overhang.size;

		// Bij kleiner maken van horizontale beams kijken of cageLadder nog in vloer ligt.
		// Anders dan tot aan het einde plaatsen.
		if (this.x + this.getWidth() > totalLengthRasterX) {
			this.x = totalLengthRasterX - this.getWidth();
		}

		// Bij kleiner mkaen van verticale beams kijken of cageLadder nog in de vloer ligt.
		// Anders tot aan eind plaatsen.
		if (this.y + this.getDepth() > totalLengthRasterY) {
			this.y = totalLengthRasterY - this.getDepth();
		}

		// Bij normale rasterchanged of verandering van overhang dan updaten naar nieuwe positie als het een top of bottom is.
		// Overhang meerekenen.
		switch (this.position) {
			case Mathematic.TOP:
				this.y = rasterStartY - overhang;
				break;
			case Mathematic.RIGHT:
				this.x = rasterEndX + overhang;
				break;
			case Mathematic.LEFT:
				this.x = rasterStartX - overhang;
				break;
			case Mathematic.BOTTOM:
				this.y = rasterEndY + overhang;
				break;
		}
	}
	rastersActive() {
		let active = true;
		this.rasters.forEach((raster) => {
			if (StairOutSide.isPossible(raster, this.position, this.endEtageIndex) === false) {
				active = false;
			}
		});
		return active;
	}
	updateRasters() {
		this.rasters = [];
		// Bij updaten rasters moeten we wel goeie raster updaten.
		// Om dat te doen moeten we overhang weer terug rekenen.

		let startX = this.x;
		let startY = this.y;
		let width = this.getWidth();
		let depth = this.getDepth();
		const offset = 5;
		const overhang = Configuration.CURRENT.overhang.size;

		switch (this.position) {
			case Mathematic.TOP:
				// Bij cageladder aan de bovenkant linksonder en rechsonder punt checken.
				startY += overhang;
				startY += offset;

				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY + depth));
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX + width, startY + depth));
				break;

			case Mathematic.RIGHT:
				// Bij cageladder aan de rechterkant linksboven en linksonder punt checken.
				startX -= overhang;
				startX -= offset;

				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY));
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY + depth));
				break;

			case Mathematic.BOTTOM:
				// Bij cageLadder aan de onderkant linkerboven en rechterboven punt checken.
				startY -= overhang;
				startY -= offset;

				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY));
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX + width, startY));
				break;

			case Mathematic.LEFT:
				// Bij cageLadder aan de linkerkant rechtsboven en rectsonder punt checken.
				startX += overhang;
				startX += offset;

				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX + depth, startY));
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX + depth, startY + depth));
				break;
		}
	}

	// !! Draw
	addDrawObjects(drawGroup) {
		let useColor = Statics.COLOR_GREY;
		if (this.selected === true) {
			useColor = Statics.COLOR_SELECTED;

			Configuration.CURRENT.dimensioning.setSizeHandleObject(this, SizeHandle.TYPE_OBJECT, false, this.dimensionsHorizontalSizeHandle, this.dimensionsVerticalSizeHandle);
		}
		if (this.hasErrors === true) {
			useColor = Statics.COLOR_ERROR;
		}

		if (typeof drawGroup === 'undefined' || drawGroup === null) {
			drawGroup = new ObjectGroup(useColor, null, null, false, this, {});
			this.drawGroupId = drawGroup.id;
		}

		let cage;

		switch (this.position) {
			case Mathematic.TOP:
			case Mathematic.BOTTOM:
				cage = new Circle(
					new DrawValue(this.x),
					new DrawValue(this.y),
					new DrawValue(this.getWidth()),
					new DrawValue(this.getDepth()),
					0,
					180,
					null,
					useColor,
					true,
					this,
					{ type: 'cageLadder', object: this },
					this.position === Mathematic.TOP ? false : true,
					this.position,
				);
				break;
			case Mathematic.LEFT:
			case Mathematic.RIGHT:
				cage = new Circle(
					new DrawValue(this.x),
					new DrawValue(this.y),
					new DrawValue(this.getDepth()),
					new DrawValue(this.getWidth()),
					90,
					270,
					null,
					useColor,
					true,
					this,
					{ type: 'cageLadder', object: this },
					this.position === Mathematic.LEFT ? true : false,
					this.position,
				);
				break;
		}

		if (cage instanceof Circle) {
			drawGroup.push(cage);
		}

		Canvas.CURRENT.addDrawObject(drawGroup);
	}

	// !! Mouse events
	onMouseDrag(evt) {
		let groupObject = Canvas.CURRENT.drawObjects.findByDrawId(this.drawGroupId);

		let moveX = evt.delta.x / Canvas.CURRENT.scaleFactor; // verplaatsing links-rechts (links - rechts +)
		let moveY = evt.delta.y / Canvas.CURRENT.scaleFactor; // verplaatsing onder-boven (boven - onder +)
		const minMaxCoordinates = groupObject.minMax();

		let minX = 0;
		let minY = 0;
		let maxX = Configuration.CURRENT.raster.spansX.getSize();
		let maxY = Configuration.CURRENT.raster.spansY.getSize();
		if (this.rasters.length > 0) {
			let xRaster = this.rasters[0].x;
			let yRaster = this.rasters[0].y;
			let maxRasterX;
			let findRasterRight;
			switch (this.position) {
				case Mathematic.TOP:
					let findRasterLeft = -1;

					let activeFound = false;
					for (let i = xRaster - 1; i >= 0 && activeFound === false; i--) {
						// zoek links naar actief
						if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster - 1)) === true) {
							findRasterLeft = i;
							activeFound = true;
						}
						// Zoek raster links en er dan boven naar actief.
						if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
							findRasterLeft = i;
							activeFound = true;
						}
					}

					maxRasterX = Configuration.CURRENT.raster.spansX.length;
					findRasterRight = maxRasterX;

					activeFound = false;

					for (let i = xRaster + 1; i <= maxRasterX && activeFound === false; i++) {
						// zoek links naar actief
						if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster - 1)) === true) {
							findRasterRight = i;
							activeFound = true;
						}
						// Zoek raster rechts en dan erboven actief.
						if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
							findRasterRight = i;
							activeFound = true;
						}
					}

					minX = Configuration.CURRENT.raster.spansX.getSize(findRasterLeft);
					maxX = Configuration.CURRENT.raster.spansX.getSize(findRasterRight - 1);

					break;
				case Mathematic.BOTTOM:
					findRasterLeft = -1;

					if (xRaster > 0) {
						let activeFound = false;
						for (let i = xRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster + 1)) === true) {
								findRasterLeft = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterLeft = i;
								activeFound = true;
							}
						}
					}

					let maxRasterX = Configuration.CURRENT.raster.spansX.length;
					let findRasterRight = maxRasterX;
					if (xRaster < maxRasterX) {
						let activeFound = false;

						for (let i = xRaster + 1; i <= maxRasterX && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster + 1)) === true) {
								findRasterRight = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterRight = i;
								activeFound = true;
							}
						}
					}
					minX = Configuration.CURRENT.raster.spansX.getSize(findRasterLeft);
					maxX = Configuration.CURRENT.raster.spansX.getSize(findRasterRight - 1);

					break;
				case Mathematic.LEFT:
					let findRasterTop = -1;

					if (yRaster > 0) {
						let activeFound = false;
						for (let i = yRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster - 1, i)) === true) {
								findRasterTop = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterTop = i;
								activeFound = true;
							}
						}
					}

					let maxRasterY = Configuration.CURRENT.raster.spansY.length;
					let findRasterBottom = maxRasterY;
					if (yRaster < maxRasterY) {
						let activeFound = false;

						for (let i = yRaster + 1; i <= maxRasterY && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster - 1, i)) === true) {
								findRasterBottom = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterBottom = i;
								activeFound = true;
							}
						}
					}
					minY = Configuration.CURRENT.raster.spansY.getSize(findRasterTop);
					maxY = Configuration.CURRENT.raster.spansY.getSize(findRasterBottom - 1);

					break;
				case Mathematic.RIGHT:
					findRasterTop = -1;

					if (yRaster > 0) {
						let activeFound = false;
						for (let i = yRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster + 1, i)) === true) {
								findRasterTop = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterTop = i;
								activeFound = true;
							}
						}
					}

					maxRasterY = Configuration.CURRENT.raster.spansY.length;
					findRasterBottom = maxRasterY;
					if (yRaster < maxRasterY) {
						let activeFound = false;

						for (let i = yRaster + 1; i <= maxRasterY && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster + 1, i)) === true) {
								findRasterBottom = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterBottom = i;
								activeFound = true;
							}
						}
					}
					minY = Configuration.CURRENT.raster.spansY.getSize(findRasterTop);
					maxY = Configuration.CURRENT.raster.spansY.getSize(findRasterBottom - 1);

					break;
			}
		}
		if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			if (minMaxCoordinates.min.y + moveY < minY) {
				// als y kleiner dan 0 dan tot aan 0
				moveY = minY - minMaxCoordinates.min.y;
				evt.delta.y = moveY * Canvas.CURRENT.scaleFactor;
			}

			if (minMaxCoordinates.max.y + moveY > maxY) {
				// als y groter dan totale lengte dan tot aan totale lengte
				moveY = maxY - minMaxCoordinates.max.y;
				evt.delta.y = moveY * Canvas.CURRENT.scaleFactor;
			}
			// this.sizeHandle.start += moveY;
		} else if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
			if (minMaxCoordinates.min.x + moveX < minX) {
				// als x kleiner dan 0 dan tot aan 0
				moveX = minX - minMaxCoordinates.min.x;
				evt.delta.x = moveX * Canvas.CURRENT.scaleFactor;
			}

			if (minMaxCoordinates.max.x + moveX > maxX) {
				// als x groter dan totale lengte dan tot aan totale lengte
				moveX = maxX - minMaxCoordinates.max.x;
				evt.delta.x = moveX * Canvas.CURRENT.scaleFactor;
			}
		}

		this.setBoundaries();

		// Update sizehandles when dragging:
		let objectSizeHandle = Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT);
		objectSizeHandle.updateDrawObjects(this.dimensionsHorizontalSizeHandle, this.dimensionsVerticalSizeHandle);

		groupObject.drawObjects.forEach((drawObject) => {
			if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
				drawObject.x.value += moveX; // naar linkes en rechts verplaatsen
				this.x += moveX;
			}
			if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
				drawObject.y.value += moveY; // naar boven/onder verplaatsen
				this.y += moveY;
			}
		});

		return { stopPropagation: true };
	}
}
