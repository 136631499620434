import * as pc from 'playcanvas';
import { Configuration } from '../configurator/configuration';
import { HicadDrawObject } from '../configurator/hicad/HicadDrawObject';
import { Store } from '../data/store';
import { Functions } from '../helpers/functions';
import { AssetManifest3D } from './assets/AssetManifest3D';

import * as orbitCamera from './scripts/orbitCamera.js';
import * as orbitCameraMouse from './scripts/orbitCameraInputMouse.js';
import * as orbitCameraTouch from './scripts/orbitCameraInputTouch.js';
import * as screenshotScript from './scripts/screenshotScript';

export class Canvas3D {
	static CURRENT = null;
	static SCREENSHOT_FREE3D = 1;
	static SCREENSHOT_FRONTVIEW = 2;
	static SCREENSHOT_RIGHTVIEW = 5;
	static SCREENSHOT_LEFTVIEW = 4;
	static SCREENSHOT_REARVIEW = 3;
	static SCREENSHOT_TOPVIEW = 6;
	objectName = 'Canvas3D';
	_canvas = null;
	_devices = null;
	_fillMode = null;
	SCRIPTS = [];
	CONTEXT_OPTIONS = { antialias: true, alpha: false, preserveDrawingBuffer: false, preferWebGl2: true };
	ASSET_PREFIX = '';
	SCRIPT_PREFIX = '';
	width = window.innerWidth - 100;
	loadId = 0;
	height = window.innerHeight - 5;

	assetManifest = new AssetManifest3D();
	// lijst van drawobjects
	drawObjects = {
		columns: [],
		landingcolumns: [],
		profiles: [],
		finishes: [],
		stairs: [],
		bracings: [],
		handrails: [],
		holes: [],
		buildingcolumns: [],
		columnprotectors: [],
		palletgates: [],
		bracecolumns: [],
		buildings: [],
		plates: [],
		ignoredhicad: [],
		stringers: [],
		stairSteps: [],
		poles: [],
		kneeRules: [],
		handRules: [],
		kickEdges: [],
		holders: [],
		torsisupport: [],
		invisiblebox: [],
		handrailbox: [],
		dimensioning: [],
		led: [],
		cageladderpart: [],
	};

	// Types van drawobjects, deze worden gebruikt om aan te geven in welke lijst van drawobject deze behoort.
	static TYPE_COLUMN = 'columns';
	static TYPE_LANDINGCOLUMN = 'landingcolumns';
	static TYPE_PROFILE = 'profiles';
	static TYPE_FINISH = 'finishes';
	static TYPE_STAIR = 'stairs';
	static TYPE_BRACING = 'bracings';
	static TYPE_HANDRAIL = 'handrails';
	static TYPE_HOLE = 'holes';
	static TYPE_BUILDINGCOLUMN = 'buildingcolumns';
	static TYPE_COLUMNPROTECTOR = 'columnprotectors';
	static TYPE_BRACECOLUMN = 'bracecolumns';
	static TYPE_BUILDING = 'buildings';
	static TYPE_PALLETGATE = 'palletgates';
	static TYPE_PLATE = 'plates';
	static TYPE_IGNOREHICAD = 'ignoredhicad';
	static TYPE_STRINGER = 'stringers';
	static TYPE_STAIRSTEP = 'stairSteps';
	static TYPE_POLE = 'poles';
	static TYPE_KNEERULE = 'kneeRules';
	static TYPE_HANDRULE = 'handRules';
	static TYPE_KICKEGDE = 'kickEdges';
	static TYPE_HOLDER = 'holders';
	static TYPE_TORSISUPPORT = 'torsisupport';
	static TYPE_INVISIBLE_BOX = 'invisiblebox';
	static TYPE_HANDRAIL_BOX = 'handrailbox';
	static TYPE_DIMENSIONING = 'dimensioning';
	static TYPE_LED = 'led';
	static TYPE_CAGELADDERPART = 'cageladderpart';

	currentLoading3dAssetCount = -1;
	app = null;
	pictures3D = [];

	// Kleinste X Y en Z van drawobjects opslaan.
	// Hiermee kunnen we de camera in het midden plaatsen.
	minX = 0;
	maxX = 0;
	minY = 0;
	maxY = 0;
	minZ = 0;
	maxZ = 0;

	// Camera globaal hier opslaan zodat we deze kunnen updaten.
	camera = null;

	constructor(force = false) {
		this.id = Functions.uuidv4();
		if (Canvas3D.CURRENT === null || force === true) {
			Canvas3D.CURRENT = this;
		}
		return Canvas3D.CURRENT;
	}
	afterReconstruct() {
		if (typeof this.assetManifest.addAsset !== 'function') {
			// in oude configuraties was assetManifest geen object maar array. Op termijn kan deze eruit
			this.assetManifest = new AssetManifest3D();
		}
	}
	resetCanvas() {
		if (this.app.root !== null && this.app.root.children !== null) {
			this.app.root.children.forEach((entity) => {
				if (entity.name !== 'orbitcamera') {
					entity.destroy();
				}
			});

			// this.app.root.children = [];
			this.emptyDrawObjects();

			this.assetManifest.removeAllAssets();
		}
	}

	// maak lijst van drawObjects leeg
	emptyDrawObjects() {
		Object.keys(this.drawObjects).forEach((item) => {
			item = [];
		});
	}

	// returnt dezelfde lijst met drawobjects alleen dan lege array, zodat we deze in de configurator kunnen hergebruiken.
	getEmptyDrawObjects() {
		let temp = {};
		Object.keys(this.drawObjects).forEach((item) => {
			temp[item] = [];
		});

		return temp;
	}

	destroyApplication() {
		// Remove assets from assetManifest and entities
		this.assetManifest.removeAllAssets();
		this.assetManifest = null;
		this.emptyDrawObjects();

		this.app.root.children.forEach((entity) => {
			entity.destroy();
		});
		this.app.destroy();
	}

	addAsset(asset) {
		if (this.assetManifest === null) {
			this.assetManifest = new AssetManifest3D();
		}
		this.assetManifest.addAsset(asset);
	}
	loadAssets() {
		this.assetManifest.load(() => {
			Configuration.CURRENT.logging3D.assetsCreatedDone = true;
			// Laden globale variableen (hoogte en diepte beams)
			setTimeout(function () {
				Configuration.CURRENT.create3DObjects();
			}, 1500);
		});
	}
	draw() {
		this.createObjects();
		this.finishDrawing();
	}

	finishDrawing() {
		this.setCameraPoint();
		Configuration.CURRENT.loadingbar3D = false;
		Store.CURRENT.configurations.in3DDrawState = true;
		this.app.maxDeltaTime = 0.3;
		this.app.autoRender = true;
		this.app.renderNextFrame = false;
		Configuration.CURRENT.logging3D.drawDone = true;
		Configuration.CURRENT.jsonDrawing = this.createHicadObjects();
	}

	createHicadObjects() {
		// haal dezelfde drawobjects op, object met zelfde propperties en voeg de geconverte objecten in de lijst.
		let drawObjects = this.getEmptyDrawObjects();
		Object.keys(this.drawObjects).forEach((drawObject) => {
			Object.keys(drawObjects).forEach((object) => {
				if (object === drawObject && this.drawObjects[drawObject] !== null && typeof this.drawObjects[drawObject] === 'object' && typeof this.drawObjects[drawObject] !== 'undefined') {
					this.drawObjects[drawObject].forEach((item) => {
						if (typeof item.convert === 'function') {
							// TODO: Voor objecten de convert functie bouwen en kijken wat hier nodig is.
							// voeg per object de function convert to om het jsonObject te returnen.
							drawObjects[object].push(item.convert());
						} else if (item.oid !== null && typeof item.oid !== 'undefined') {
							// Als die niet bestaat gewoon zichzelf returnen.
							drawObjects[object].push(new HicadDrawObject(item));
						} else if (item.objectName === 'Box3D') {
							drawObjects[object].push(new HicadDrawObject(item));
						}
					});
				}
			});
		});
		return drawObjects;
	}

	// roep van alle drawobjects de functie draw aan, zodat deze in 3D getekent kunnen worden.
	createObjects() {
		Object.keys(this.drawObjects).forEach((item) => {
			if (this.drawObjects[item].length > 0) {
				this.drawObjects[item].forEach((drawObject) => {
					if (typeof drawObject.draw === 'function') {
						drawObject.draw(this.app);
					}
				});
			}
		});
	}

	// voegt aan object drawobject en daarin de array het object toe
	addDrawObject(object, type) {
		if (type !== null && typeof type !== 'undefined') {
			this.drawObjects[type].push(object);
		}
	}

	setCameraPoint() {
		// Get the camera entity
		let cameraEntity = this.app.root.children.filter(function (item) {
			return item.name === 'orbitcamera';
		});

		// Checken of camera bestaat en dit focuspoint veranderen.
		if (cameraEntity !== null && typeof cameraEntity !== 'undefined') {
			if (cameraEntity.length === 1) {
				cameraEntity = cameraEntity[0];
				cameraEntity.c.script.orbitCamera.pitch = -30;
				cameraEntity.c.script.orbitCamera.distance = 40;
				cameraEntity.c.script.orbitCamera.yaw = 0;
				cameraEntity.c.script.orbitCamera.pivotPoint = new pc.Vec3(this.minX + (this.maxX - this.minX) / 2, 1.25, (this.maxZ - this.minZ) / 2);
			}
		} else {
			Configuration.CURRENT.logging3D.newError({
				text: 'CameraEntity not found',
			});
		}
	}

	init() {
		this.canvas = document.getElementById('3d');
		// Create the application and start the update loop
		this.app = new pc.Application(this.canvas, { mouse: new pc.Mouse(document.body), touch: new pc.TouchDevice(document.body), keyboard: new pc.Keyboard(window) });
		this.app.setCanvasFillMode(pc.FILLMODE_FILL_WINDOW);
		this.app.start();
		this.app.setCanvasResolution(pc.RESOLUTION_AUTO);
		window.addEventListener('resize', function () {
			Canvas3D.CURRENT.app.resizeCanvas(window.innerWidth - 50, window.innerHeight);
		});
		this.createLayers();
		this.addCamera();
		this.addLights();
	}
	createLayers() {
		// Lagen maken waarin we deckingFinish stoppen en een laag voor de gaten in de deckingFinish.

		this.deckingFinishLayer = new pc.Layer({ name: 'deckingfinishlayer' });
		this.transparentObjectsLayer = new pc.Layer({ name: 'transparentobjectslayer' });

		this.app.scene.layers.pushOpaque(this.deckingFinishLayer);
		this.app.scene.layers.pushTransparent(this.transparentObjectsLayer);
	}

	addLights() {
		let floorWidth = Configuration.CURRENT.etages.floor.width / 1000;
		let floorDepth = Configuration.CURRENT.etages.floor.length / 1000;

		let deckingFinishLayer = this.app.scene._layers.getLayerByName('deckingfinishlayer');
		let transparentObjectsLayer = this.app.scene._layers.getLayerByName('transparentobjectslayer');

		let lightTop = new pc.Entity('lightTop');
		lightTop.addComponent('light', { type: pc.LIGHTTYPE_DIRECTIONAL, color: new pc.Color(240 / 255, 240 / 255, 240 / 255), intensity: 1 });
		lightTop.setPosition(floorWidth / 2, 5, floorDepth / 2);
		lightTop.rotateLocal(0, 0, 0);
		lightTop.light.layers = [deckingFinishLayer.id];
		this.app.root.addChild(lightTop);

		let lightRight = new pc.Entity('lightRight');
		lightRight.addComponent('light', { type: pc.LIGHTTYPE_DIRECTIONAL, color: new pc.Color(240 / 255, 240 / 255, 240 / 255), intensity: 1.1 });
		lightRight.setPosition(floorWidth + 50, 1, floorDepth / 2);
		lightRight.rotateLocal(-90, 0, 0);
		lightRight.light.layers = [deckingFinishLayer.id, transparentObjectsLayer.id, pc.LAYERID_WORLD];
		this.app.root.addChild(lightRight);

		let lightLeft = new pc.Entity('lightLeft');
		lightLeft.addComponent('light', { type: pc.LIGHTTYPE_DIRECTIONAL, color: new pc.Color(240 / 255, 240 / 255, 240 / 255), intensity: 1.1 });
		lightLeft.setPosition(-50, 1, floorDepth / 2);
		lightLeft.rotateLocal(90, 0, 0);
		lightLeft.light.layers = [deckingFinishLayer.id, transparentObjectsLayer.id, pc.LAYERID_WORLD];
		this.app.root.addChild(lightLeft);

		let lightFront = new pc.Entity('lightFront');
		lightFront.addComponent('light', { type: pc.LIGHTTYPE_DIRECTIONAL, color: new pc.Color(240 / 255, 240 / 255, 240 / 255), intensity: 1.1 });
		lightFront.setPosition(floorWidth / 2, 1, floorDepth + 50);
		lightFront.rotateLocal(0, 0, 90);
		lightFront.light.layers = [deckingFinishLayer.id, transparentObjectsLayer.id, pc.LAYERID_WORLD];
		this.app.root.addChild(lightFront);

		let lightBack = new pc.Entity('lightBack');
		lightBack.addComponent('light', { type: pc.LIGHTTYPE_DIRECTIONAL, color: new pc.Color(240 / 255, 240 / 255, 240 / 255), intensity: 1.1 });
		lightBack.setPosition(floorWidth / 2, 1, floorDepth - 50);
		lightBack.rotateLocal(0, 0, -90);
		lightBack.light.layers = [deckingFinishLayer.id, transparentObjectsLayer.id, pc.LAYERID_WORLD];
		this.app.root.addChild(lightBack);
		this.app.scene.ambientLight = new pc.Color(0.25, 0.25, 0.25);
	}
	addCamera() {
		let foundCameraEntity = this.app.root.children.find((e) => e.name === 'orbitcamera');
		if (foundCameraEntity === null || typeof foundCameraEntity === 'undefined') {
			orbitCamera.start(this.app, pc);
			orbitCameraMouse.start(this.app, pc);
			orbitCameraTouch.start(this.app, pc);

			let camera = new pc.Entity('orbitcamera');
			camera.addComponent('camera', { clearColor: new pc.Color(0.41, 0.46, 0.51), priority: 10 });

			let deckingFinishLayer = this.app.scene._layers.getLayerByName('deckingfinishlayer');
			let transparentObjectsLayer = this.app.scene._layers.getLayerByName('transparentobjectslayer');

			camera.camera.layers = [deckingFinishLayer.id, transparentObjectsLayer.id, pc.LAYERID_WORLD];
			camera.addComponent('script');
			camera.script.create('orbitCamera', { attributes: { inertiaFactor: 0 } }); // Override default of 0 (no inertia)
			camera.script.create('orbitCameraInputMouse');
			camera.script.create('orbitCameraInputTouch');
			camera.frustumCulling = true;

			this.app.root.addChild(camera);
		}

		let foundScreenshotEntity = this.app.root.children.find((e) => e.name === 'screenshotEntity');
		if (foundScreenshotEntity === null || typeof foundScreenshotEntity === 'undefined') {
			if (this.app.scripts.has('screenshot') === false) {
				screenshotScript.start(this.app, pc, Canvas3D.CURRENT);
			}

			let screenshotEntity = new pc.Entity('screenshotEntity');
			screenshotEntity.addComponent('script');
			screenshotEntity.script.create('screenshot');
			this.app.root.addChild(screenshotEntity);
		}
	}
	takeScreenshotPuppeteer() {
		this.app.fire('ui:pupperteerTakeScreenshots');
	}
	takeScreenshot(multiple) {
		if (multiple) {
			this.app.fire('ui:takeMultipleScreenshots');
		} else {
			this.app.fire('ui:takeScreenshot');
		}
	}
	saveScreenshotERP(prop) {
		let data = { calculationId: Store.CURRENT.configurations.activeConfigId };
		if (typeof prop === 'object' && prop !== null) {
			data.drawings = prop;
		} else if (typeof prop === 'string' && prop !== null) {
			data.drawings = [{ type: Canvas3D.SCREENSHOT_FREE3D, floor: 0, drawing: prop }];
		}
		Store.CURRENT.configurations.takePicture(
			data,
			null,
			(result) => {
				window.Vue.$message.success(typeof prop === 'string' && prop !== null ? window.Vue.$translate('picture.3d.saved') : window.Vue.$translate('pictures.3d.saved'));
				Configuration.CURRENT.takingScreenshot = false;
			},
			(error) => {
				Configuration.CURRENT.takingScreenshot = false;
				console.log(error);
			},
		);
	}
	toggleDimensioning(value) {
		// Op basis van deze waarde de entities van dimensioning opzoeken en aan of uit zetten.
		let entities = Canvas3D.CURRENT.app.root.findByTag('dimensioning-3d');
		entities.forEach((entity) => {
			entity.enabled = value;
		});
	}
	drawNewDimesioning() {
		if (Configuration.CURRENT.showDimensioning3D === true) {
			// Leegmaken drawObjects omdat hij anders bij nieuwe types dubbel gaat tekenen.
			this.drawObjects[Canvas3D.TYPE_DIMENSIONING] = [];

			let entities = Canvas3D.CURRENT.app.root.findByTag('dimensioning-3d');
			entities.forEach((entity) => {
				entity.destroy();
			});

			// Toevoegen van de nieuwe Dimensioning.
			Configuration.CURRENT.raster.addDrawObjects3d();

			// Na toevoegen die drawobjecs ophalen uit de array.
			let newDrawObjects = this.drawObjects[Canvas3D.TYPE_DIMENSIONING];

			newDrawObjects.forEach((dimensioningDrawObject) => {
				dimensioningDrawObject.draw();
			});
		}
	}
	toggleHandRailLed() {
		let entities = Canvas3D.CURRENT.app.root.findByTag('ledbar-3d');
		entities.forEach((entity) => {
			entity.enabled = Configuration.CURRENT.showHandrailLed;
		});
	}
	updateColorHandrailLed() {
		let entities = Canvas3D.CURRENT.app.root.findByTag('ledbar-3d');
		const colorValues = this.hexToRGB(Configuration.CURRENT.handrailLedColor);
		const material = this.createLedMaterial(colorValues);

		entities.forEach((entity) => {
			// Wanneer niet undefined dan is het de lamp tussen de paaltjes.
			if (entity.render !== null && typeof entity.render !== 'undefined') {
				entity.render.material = material;
				entity.render.meshInstances[0].material = entity.render.material;
			} else if (entity.light !== null && typeof entity.light !== 'undefined') {
				entity.light.color = new pc.Color(colorValues.r / 255, colorValues.g / 255, colorValues.b / 255);
			}
		});
	}
	getModelData(model, prop) {
		let findAsset = this.app.assets._assets.filter((asset) => asset.name === model + '.json');
		let findIndex = 0;

		// Als er een model vanuit ERP is en een model wat dezelfde fallback zal de findasset groter dan 1 zijn, dit moeten we nog afvangen welke we dan moeten hebben,
		// We pakken dan degene die overeenkomt met de opgegeven naam, dit zal de naam vanuit de configurator zijn en niet de fallbackname.
		if (findAsset.length > 1) {
			findAsset.forEach((asset, index) => {
				if (asset.name !== model + '.json') {
					findIndex = index;
				}
			});
		} else if (findAsset.length > 0) {
			let obj = findAsset[findIndex].modelData;
			if (obj !== null && typeof obj !== 'undefined') {
				if (obj.hasOwnProperty(prop)) {
					if (obj[prop] !== null && typeof obj[prop] !== 'undefined' && obj[prop] !== 0) {
						return obj[prop];
					}
				}
			}
		} else {
			console.log('ModelData not found', '\n', 'Model:', model, '\n', 'Prop:', prop, '\n', new Error());
			// Configuration.CURRENT.logging3D.newError({
			// 	text: 'Modeldata not found',
			// 	model: model,
			// 	prop: prop,
			// 	assets: { ...this.app.assets._assets },
			// });
		}
		return 0;
	}

	getModelDataByOid(oid, prop) {
		let findAsset = this.app.assets._assets.filter((asset) => asset.oid === oid);
		if (findAsset.length === 1) {
			let obj = findAsset[0].modelData;
			if (obj !== null && typeof obj !== 'undefined') {
				if (obj.hasOwnProperty(prop)) {
					if (obj[prop] !== null && typeof obj[prop] !== 'undefined') {
						if (obj[prop] === 0) {
							console.log('Prop gevonden maar is 0', oid, prop, findAsset[0].name, findAsset);
						}
						return obj[prop];
					}
				}
			}
		} else {
			console.log('Kan geen modeldata ophalen van item met OID', oid, new Error());
			return 0;
		}
	}
	// Van het opgevraagde model proberen de fallbackdata op te halen
	// Als deze niet bestaat 0 returnen zodat we in iedergeval het getekende zien.
	getFallBackData(asset, prop) {
		if (typeof asset !== 'undefined' && asset !== null) {
			if (typeof asset.fallBackData !== 'undefined' && asset.fallBackData !== null) {
				if (asset.fallBackData.hasOwnProperty(prop)) {
					let foundProp = asset.fallBackData[prop];
					if (foundProp !== null && typeof foundProp !== 'undefined') {
						return foundProp;
					}
				}
			}
		}
		return 0;
	}
	getBeamData(oid, prop) {
		let findAsset = this.app.assets._assets.find((asset) => asset.oid === oid);

		if (findAsset !== null && typeof findAsset !== 'undefined') {
			if (findAsset.modelData.hasOwnProperty(prop)) {
				return findAsset.modelData[prop];
			} else {
				return 0;
			}
		} else {
			console.log('Beam asset not found', '\n', 'Assets:', this.app.assets._assets, '\n', 'OID:', oid, '\n', 'prop:', prop, '\n', new Error());
			return 0;
		}
	}
	hexToRGB(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}
	createLedMaterial(colorValues) {
		let newMaterial = new pc.StandardMaterial();
		newMaterial.opacityMap = Canvas3D.CURRENT.app.assets.find('blur-2.jpg', 'texture').resource;
		newMaterial.opacityMapChannel = 'r';

		newMaterial.emissive = new pc.Color(colorValues.r / 255, colorValues.g / 255, colorValues.b / 255);
		newMaterial.diffuse = new pc.Color(colorValues.r / 255, colorValues.g / 255, colorValues.b / 255);
		newMaterial.emissiveIntensity = 0.12;
		newMaterial.shadingModel = pc.SPECULAR_BLINN;
		newMaterial.blendType = pc.BLEND_ADDITIVEALPHA;

		newMaterial.update();

		return newMaterial;
	}
}
