/* eslint-disable no-lonely-if */
import { Functions } from '../helpers/functions';
import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { ObjectGroup } from '../draw/objectGroup';
import { Mathematic } from '../helpers/mathematic';
import { BuildingColumn3D } from '../draw3d/buildingcolumn/buildingColumn3D';
import { Configuration } from './configuration';

import { BuildingColumnTrimmings } from './trimmings/buildingColumnTrimmings';
import { CustomError } from './CustomError';
import { Errors } from './errors';
import { BuildingColumns } from './buildingColumns';
import { RemoveRaster } from './removeRaster';
import { Canvas3D } from '../draw3d/Canvas3D';
import { Columns } from './columns';
import { Profiles } from './profiles';
import { SizeHandle } from '../draw/sizeHandle';
import { Canvas } from '../draw/canvas';

export class BuildingColumn {
	objectName = 'BuildingColumn';
	id = '';
	rasters = [];
	selected = false;
	active = true;

	x = 0;
	y = 0;
	width = 1000;
	depth = 1000;
	height = 0;
	startHeight = 0;
	customHeight = false;
	defaultExtraHeight = 2000;

	// Nieuwe manier, nu gewoon voor alle kolommen dezelfde hoogte.
	// We voegen ze toch alleen maar toe als dat nodig is.
	// Anders verwijderen we ze weer.
	columnsHeight = 0;

	newBuildingColumn = true;
	mousePriority = 20;
	buildingColumnTrimmings = new BuildingColumnTrimmings();
	constructor(newBuildingColumnConfiguration, clickedRasterXY) {
		this.id = Functions.uuidv4();
		if (typeof newBuildingColumnConfiguration !== 'undefined' && newBuildingColumnConfiguration !== null) {
			this.update(newBuildingColumnConfiguration);
		}

		if (typeof clickedRasterXY !== 'undefined' && clickedRasterXY !== null) {
			// Na kiezen plek / raster positie bepalen op geklikte raster.
			this.setPositionNewBuildingColumn(clickedRasterXY);
			this.updateTrimming();
			this.addUsedSurface();
		}

		this.onChange();
	}
	setPositionNewBuildingColumn(clickedRaster) {
		if (clickedRaster.position === 'horizontal') {
			// kolom op de helft (kolomx1 + (kolomx2-kolomx1-breedte)/2) plaatsen
			this.x =
				Configuration.CURRENT.raster.getSizeX(clickedRaster.x - 1) +
				(Configuration.CURRENT.raster.getSizeX(clickedRaster.x) - Configuration.CURRENT.raster.getSizeX(clickedRaster.x - 1) - this.width) / 2;
			// standaard niet buiten tekening vallen
			// als links buiten de tekening dan op 0
			if (this.x < 0) {
				this.x = 0;
			}
			// als rechts buiten de tekening dan tot aan de rand
			if (this.x + this.width > Configuration.CURRENT.raster.getSizeX()) {
				this.x = Configuration.CURRENT.raster.getSizeX() - this.width;
			}
			// y-positie is die van het raster
			this.y = Configuration.CURRENT.raster.getSizeY(clickedRaster.y - 1);

			if (this.y > 0) {
				// niet eerste maar laatste rij dan diepte eraf
				this.y -= this.depth;
			}
		} else {
			// x-positie die van het raster
			this.x = Configuration.CURRENT.raster.getSizeX(clickedRaster.x - 1);
			if (this.x > 0) {
				// niet eerste maar laatste rij dan diepte eraf
				this.x -= this.width;
			}

			// kolom op de helft (kolomx1 + (kolomx2-kolomx1-breedte)/2) plaatsen
			this.y =
				Configuration.CURRENT.raster.getSizeY(clickedRaster.y - 1) +
				(Configuration.CURRENT.raster.getSizeY(clickedRaster.y) - Configuration.CURRENT.raster.getSizeY(clickedRaster.y - 1) - this.depth) / 2;
			// standaard niet buiten tekening vallen
			// als boven buiten de tekening dan op 0
			if (this.y < 0) {
				this.y = 0;
			}

			// als onder buiten de tekening dan tot aan de rand
			if (this.y + this.depth > Configuration.CURRENT.raster.getSizeY()) {
				this.y = Configuration.CURRENT.raster.getSizeY() - this.depth;
			}
		}
	}

	addUsedSurface() {
		this.removeUsedSurface();
		// Over etages loopen en kijken tot waar hij komt,
		let totalHeight = 0;
		Configuration.CURRENT.etages.etages.forEach((etage) => {
			// Alleen surface berekenen als de starthoogte minder is dan etage en totale hoogte gebouwkolom boven de huidige etage zit.
			// Dan weten we dat er collision is met deckingfinish en dan dus surface berekenen.
			if (this.startHeight < totalHeight + etage.height && this.startHeight + this.height > totalHeight + etage.height) {
				let calcWidth = null;
				let calcDepth = null;
				let topLeftActive = etage.isActiveRaster(new RemoveRaster(etage.raster.getRasterByCoordinate(this.x, this.y).x, etage.raster.getRasterByCoordinate(this.x, this.y).y));
				let topRightActive = etage.isActiveRaster(
					new RemoveRaster(etage.raster.getRasterByCoordinate(this.x + this.width, this.y).x, etage.raster.getRasterByCoordinate(this.x + this.width, this.y).y),
				);
				let bottomLeftActive = etage.isActiveRaster(
					new RemoveRaster(etage.raster.getRasterByCoordinate(this.x, this.y + this.depth).x, etage.raster.getRasterByCoordinate(this.x, this.y + this.depth).y),
				);
				let bottomRightActive = etage.isActiveRaster(
					new RemoveRaster(etage.raster.getRasterByCoordinate(this.x + this.width, this.y + this.depth).x, etage.raster.getRasterByCoordinate(this.x + this.width, this.y + this.depth).y),
				);

				// Gebouwkolom steekt aan de buitenkanten uit
				let totalM2 = 0;

				if (!bottomRightActive && !bottomLeftActive && !topLeftActive && !topRightActive) {
					totalM2 = 0;
					return;
				} else {
					let horizontal;
					let vertical;
					// ** Berekenen hoeveel m2 er buiten de vloer of inactief raster ligt.
					if (topLeftActive === false) {
						// Wanneer beginpunt al buiten vloer ligt aan de linkerkant dan is min getal van X vanaf nul gezien de horizontale lengte.
						if (this.x < 0) {
							horizontal = Math.abs(this.x);
						} else {
							// Wanneer in de vloer ligt dan vanaf eind raster min begin X = hoeveelheid buiten de vloer.
							horizontal = etage.raster.getSizeX(etage.raster.getRasterByCoordinate(this.x, this.y).x) - this.x;
						}

						// Wanneer beginpunt al buiten vloer ligt aan de bovenkant dan is min getal van Y anaf nul gezien de verticale lengte.
						if (this.y < 0) {
							vertical = Math.abs(this.y);
						} else {
							// Wanneer in de vloer ligt dan vanaf eind raster min begin Y = hoeveelheid buiten de vloer.
							vertical = etage.raster.getSizeY(etage.raster.getRasterByCoordinate(this.x, this.y).y) - this.y;
						}

						// Hoek gedeelte linksboven berekenen.

						if (topRightActive === false || this.y < 0) {
							horizontal = this.width / 2;
						}
						if (bottomLeftActive === false || this.x < 0) {
							vertical = this.depth / 2;
						}

						if (Math.sign(horizontal) !== -1 && Math.sign(vertical) !== -1) {
							totalM2 += horizontal * vertical;
						}
					}

					if (topRightActive === false) {
						// Wanneer einpunt buiten vloer ligt dan verschil eindpunt en einde vloer is horizontale lengte.
						if (this.x + this.width > etage.floor.width) {
							horizontal = this.x + this.width - etage.floor.width;
						} else {
							horizontal = this.x + this.width - etage.raster.getSizeX(etage.raster.getRasterByCoordinate(this.x, this.y).x);
						}

						if (this.y < 0) {
							vertical = Math.abs(this.y);
						} else {
							vertical = etage.raster.getSizeY(etage.raster.getRasterByCoordinate(this.x, this.y).y) - this.y;
						}

						if (bottomRightActive === false || this.x + this.width > etage.floor.width) {
							vertical = this.depth / 2;
						}
						if (topLeftActive === false || this.y < 0) {
							horizontal = this.width / 2;
						}

						if (Math.sign(horizontal) !== -1 && Math.sign(vertical) !== -1) {
							totalM2 += horizontal * vertical;
						}
					}

					if (bottomLeftActive === false) {
						// Wanneer beginpunt al buiten vloer ligt aan de linkerkant dan is min getal van X vanaf nul gezien de horizontale lengte.
						if (this.x < 0) {
							horizontal = Math.abs(this.x);
						} else {
							// Wanneer in de vloer ligt dan vanaf eind raster min begin X = hoeveelheid buiten de vloer.
							horizontal = etage.raster.getSizeX(etage.raster.getRasterByCoordinate(this.x, this.y).x) - this.x;
						}

						// Wanneer eindpunt linksonder buiten vloer ligt dan dat berekeken - vloerlengte.
						if (this.y + this.depth > etage.floor.length) {
							vertical = this.y + this.depth - etage.floor.length;
						} else {
							// Wanneer in de vloer ligt dan vanaf eind raster min begin Y = hoeveelheid buiten de vloer.
							vertical = etage.raster.getSizeY(etage.raster.getRasterByCoordinate(this.x, this.y).y) - this.y;
						}

						if (bottomRightActive === false || this.y + this.depth > etage.floor.length) {
							horizontal = this.width / 2;
						}
						if (topLeftActive === false || this.x < 0) {
							vertical = this.depth / 2;
						}

						if (Math.sign(horizontal) !== -1 && Math.sign(vertical) !== -1) {
							totalM2 += horizontal * vertical;
						}
					}

					if (bottomRightActive === false) {
						// Wanneer einpunt buiten vloer ligt dan verschil eindpunt en einde vloer is horizontale lengte.
						if (this.x + this.width > etage.floor.width) {
							horizontal = this.x + this.width - etage.floor.width;
						} else {
							horizontal = this.x + this.width - etage.raster.getSizeX(etage.raster.getRasterByCoordinate(this.x, this.y).x);
						}

						// Wanneer eindpunt linksonder buiten vloer ligt dan dat berekeken - vloerlengte.
						if (this.y + this.depth > etage.floor.length) {
							vertical = this.y + this.depth - etage.floor.length;
						} else {
							// Wanneer in de vloer ligt dan vanaf eind raster min begin Y = hoeveelheid buiten de vloer.
							vertical = etage.raster.getSizeY(etage.raster.getRasterByCoordinate(this.x, this.y).y) - this.y;
						}

						if (bottomLeftActive === false || this.y + this.depth > etage.floor.length) {
							horizontal = this.width / 2;
						}

						if (topRightActive === false || this.x + this.width > etage.floor.width) {
							vertical = this.depth / 2;
						}

						if (Math.sign(horizontal) !== -1 && Math.sign(vertical) !== -1) {
							totalM2 += horizontal * vertical;
						}
					}

					if (bottomRightActive && bottomLeftActive && topLeftActive && topRightActive) {
						totalM2 = this.width * this.depth;
					}
				}

				if (calcDepth !== null && calcWidth !== null) {
					etage.usedSurface.push({
						width: calcWidth,
						depth: calcDepth,
						id: this.id,
					});
				} else {
					let wholeColumnM2 = this.width * this.depth;
					let minus = wholeColumnM2 - totalM2;
					// Wanneer gehele gebouwkolom in de vloer staat, dan doen we hele m2 - totalem2 = 0, dus dan gewoon hele gebwoukolom oppervlakte eraf halen.
					if (minus === 0) {
						minus = wholeColumnM2;
					}

					etage.usedSurface.push({
						width: Math.sqrt(minus),
						depth: Math.sqrt(minus),
						id: this.id,
					});
				}
			}
			totalHeight += etage.height;
		});
	}

	removeUsedSurface() {
		// Removen van item uit etage/usedSurface, deze functie aanroepen na mouse up, dus nadat de gebouwkolom verplaatst is,
		// dan gooien we het huidige item weg en berekenen we de nieuwe waarden, de gebouwkolom zou immers nu gedeelteijk niet in de vloer kunnen staan.
		Configuration.CURRENT.etages.etages.forEach((etage) => {
			etage.usedSurface.forEach((surf, index) => {
				if (surf.id === this.id) {
					etage.usedSurface.splice(index, 1);
				}
			});
		});
	}

	boundaries = [];
	errors = new Errors();
	get hasErrors() {
		return this.errors.length > 0;
	}
	getErrors() {
		return this.errors;
	}
	calculateAmount(params) {
		// aangeroepen om hoeveelheden te berekenen
		// op dit moment moet buildingColumn nog na profiles komen omdat de array daar leeggegooid wordt en vanuit hier weer gevuld wordt
		// weggooien van oude hoeft dus niet
		params.buildColumnId = this.id;
		this.buildingColumnTrimmings.calculateAmount(params);
	}
	getContextMenu() {
		// bij bestaande objecten gaat getContextMenu fout. Daarom niet via variabele maar hier direct in return
		return [
			{ icon: 'edit', action: this.edit.bind(this), active: true },
			{ icon: 'delete', action: this.remove.bind(this), active: true },
		];
	}
	edit() {
		Configuration.CURRENT.contextMenu.hide();

		// omdat contextmenu op de kolom zelf staat is hier edit nodig die komt echter vanuit buildingcolumns vandaar opgelost met meegeven
		if (typeof this._edit === 'function') {
			this._edit(this);
		}
	}
	remove() {
		Configuration.CURRENT.contextMenu.hide();
		this.removeUsedSurface();
		// omdat contextMenu op de kolom zelf staat is hier remove  nodig die komt echter vanuit buildingcolumns vandaar opgelost met meegeven
		if (typeof this._remove === 'function') {
			this._remove(this);
		}

		this.removeUsedSurface();
		this.onChange();
	}
	insertUniqueRaster(raster) {
		if (raster.x > -1 && raster.y > -1) {
			const rasterFound = this.rasters.find((rst) => rst.x === raster.x && rst.y === raster.y);
			if (typeof rasterFound === 'undefined') {
				this.rasters.push(raster);
			}
		}
	}
	// Rasterchanged vanuit rasterchanged -> Etage -> Configuration
	// Niet calculate aanroepen omdat anders de trimming word geupdate met set
	onRasterChanged() {
		this.findRasters();
		this.updateTrimming();
	}
	// Calculate word uitgevoerd bij
	calculate() {
		this.findRasters();

		// CollisionCheck hier omdat hij na mouseup -> calculate uitvoert, en ook calculate uitvoert na updaten van breedte diepte etc, en hij doet het op rasterChanged.
		let groupObject = Configuration.CURRENT.canvas.drawObjects.findByDrawId(this.drawGroupId);
		let collisions = false;
		let collisionCheck = this.checkCollisions(this.boundaries, this);
		if (typeof collisionCheck !== 'undefined' && collisionCheck.errors.filter((e) => e.objectName !== 'Column').length > 0) {
			// kijk of er collisions zijn. Evenutele collisions met een kolom worden opgelost doordat de kolom dan op disabled wordt gezet
			collisions = true;
		}
		this.setCollisionDrawObject(groupObject.drawObjects, collisions);

		// TODO: Is updaten van boundaries nodig bij een rasterchanged?
		this.boundaries = [
			{
				topLeft: { x: this.x, y: this.y },
				topRight: { x: this.x + this.width, y: this.y },
				bottomLeft: { x: this.x, y: this.y + this.depth },
				bottomRight: { x: this.x + this.width, y: this.y + this.depth },
			},
		];

		this.updateTrimming();

		// Configuration.CURRENT.columns.getByName(this.id).forEach((column) => {
		// 	this.boundaries.push(column.boundaries[0]);
		// });
	}

	findRasters() {
		this.rasters = [];
		this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(this.x, this.y)); // raster kolom links-boven
		this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(this.x, this.y + this.width)); // raster kolom rechts-boven
		this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(this.x + this.width, this.y)); // raster kolom links-onder
		this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(this.x + this.width, this.y + this.depth)); // raster kolom rechts-onder
	}
	select(parameters) {
		if (this.id === parameters.id) {
			this.selected = !this.selected;
		} else {
			// Wanneer nieuwe object niet dit object is dan is het een deselect.
			this.selected = false;
		}
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}
	overlap(boundaries) {
		let overlap = false;
		boundaries.forEach((boundary) => {
			this.boundaries.forEach((boundaryBuildingColumn) => {
				if (Mathematic.overlapRectangles(boundary.topLeft, boundary.bottomRight, boundaryBuildingColumn.topLeft, boundaryBuildingColumn.bottomRight) === true) {
					overlap = true;
				}
			});
		});
		return overlap;
	}
	collisions(boundaries, self) {
		if (this.id === self.id) {
			// zichzelf dan geen  fout
			return { result: false, errors: [], objectName: this.objectName };
		}
		if (this.id === self.name) {
			// Als het een kolom is die hoort bij de gebouwkolom dan lijkt er een overlapping maar is het niet zo omdat ze dicht bij elkaar staan
			return { result: false, errors: [], objectName: this.objectName };
		}

		// voorlopig ervan uitgaand dat het een vierkant betreft
		if (this.overlap(boundaries) === true) {
			return { result: true, errors: [new CustomError(window.Vue.$translate('collision.buildingColumn'), Errors.ERRORTYPE.collision, this.objectName)] };
		}
		return { result: false, errors: [], objectName: this.objectName };
	}
	collisionCheck() {
		let collisionCheck = this.checkCollisions(this.boundaries, this);
		let hasNowErrors = this.hasErrors;

		if (collisionCheck.result) {
			this.errors.clear(Errors.ERRORTYPE.collision);
			collisionCheck.errors.forEach((error) => {
				this.errors.add(error);
			});
		} else {
			this.errors.clear(Errors.ERRORTYPE.collision);
		}
		if (hasNowErrors !== this.hasErrors) {
			let drawObject = Configuration.CURRENT.canvas.drawObjects.get(this.drawObject);
			if (drawObject !== null) {
				this.setCollisionDrawObject(drawObject.drawObjects, this.hasErrors);
			}
		}

		return collisionCheck;
	}
	getEdgePosition() {
		let startX = this.boundaries[0].topLeft.x;
		let startY = this.boundaries[0].topLeft.y;
		let endX = this.boundaries[0].topRight.x;
		let endY = this.boundaries[0].bottomLeft.y;

		return { startX, startY, endX, endY };
	}
	onProfilePosition(profilePosition, height) {
		// Geen colliion op hoogte
		// Kijken of hoogte kleine is dan startHoogte gebouwkolom, als dat al false is dan is er geen collision.
		// Dan kijken of starthoogte onder de etagehoogte ligt en de etagehoogte groter is dan de totale gebouwkolom hoogte.
		// En kijken of de hoogte niet groter is dan de totale hoogte gebouwkolom, anders dan is collision ook niet mogelijk.
		if (height < this.startHeight || (this.startHeight < height && height > this.startHeight + this.height) || height > this.startHeight + this.height || this.boundaries.length === 0) {
			return false;
		}

		return (
			(this.x >= profilePosition.startX &&
				this.x <= profilePosition.endX &&
				this.x + this.width >= profilePosition.startX &&
				this.x + this.width <= profilePosition.endX &&
				profilePosition.startY >= this.y &&
				profilePosition.endY <= this.y + this.depth) ||
			(this.y >= profilePosition.startY &&
				this.y <= profilePosition.endY &&
				this.y + this.depth >= profilePosition.startY &&
				this.y + this.depth <= profilePosition.endY &&
				profilePosition.startX >= this.x &&
				profilePosition.endX <= this.x + this.width) ||
			(this.x <= profilePosition.startX && this.x + this.width >= profilePosition.startX && this.y <= profilePosition.startY && this.y + this.depth >= profilePosition.startY) ||
			(this.x <= profilePosition.endX && this.x + this.width >= profilePosition.endX && this.y <= profilePosition.endY && this.y + this.width >= profilePosition.endY)
		);

		//* 1 * Ligt de gebouwkolom na het begin van de profielX en voor het einde van profielX */
		//* 2 * Ligt de gebouwkolom na het profielY en voor het eind profielY */
		//* 3 * Ligt het begin van de gebouwkolom voor de startX , maar het einde X gebouwkolom na het beginpunt van profielX */
		//* 4 * Ligt het begin van de gebouwkolom voor de startX , maar het einde X gebouwkolom na het beginpunt van profielX */
	}
	setCollisionDrawObject(drawObjects, collisions) {
		drawObjects.forEach((drawObject) => {
			// na collisions-check nogmaals objecten doorlopen om kleur goed te zetten.

			if (collisions === true) {
				drawObject.setLineColor(BuildingColumns.COLORS.collision);
				drawObject.setFillColor(BuildingColumns.COLORS.collision);
				drawObject.setColor(BuildingColumns.COLORS.collision);
			} else {
				drawObject.setLineColor(drawObject.objectParams.color);
				drawObject.setFillColor(drawObject.objectParams.color);
				drawObject.setColor(drawObject.objectParams.color);
			}
		});
	}
	checkCollisions(boundaries, self) {
		if (typeof this._checkCollisions === 'function') {
			return this._checkCollisions(boundaries, self);
		}
	}

	setReferences(params) {
		this._onChange = params.onChange;
		this._redraw = params.redraw;
		this._edit = params.edit;
		this._checkCollisions = params.checkCollisions;
		this._remove = params.remove;
		this.addUsedSurface();
	}
	removeReferences() {
		this._onChange = null;
		this._edit = null;
		this._checkCollisions = null;
		this._remove = null;
	}
	onMouseMove(evt, drawObject) {
		Canvas.CURRENT.canvas.style.cursor = 'move';
		return { stopPropagation: true };
	}
	onMouseLeave(evt, object, canvas) {
		Canvas.CURRENT.canvas.style.cursor = 'default';
		return { stopPropagation: true };
	}

	onMouseUp(evt, drawObject) {
		this.addUsedSurface();
		this.updateTrimming();
		this.calculate();
		this.onChange();
		return { stopPropagation: true };
	}
	onMouseDrag(evt, drawObject) {
		// Group ID opgeslagen, alleen selecteren op rectangle maar wel hele groep verslepen.
		let groupObject = Canvas.CURRENT.drawObjects.findByDrawId(this.drawGroupId);

		// verschuiving schalen naar juiste maten evt.delta is geschaalde maat
		let deltaX = evt.delta.x / Canvas.CURRENT.scaleFactor;
		let deltaY = evt.delta.y / Canvas.CURRENT.scaleFactor;

		// de verschuiving toepassen op de kolom voor redraw
		this.x += deltaX;
		this.y += deltaY;

		// Verschuiven van trimming
		this.updateTrimming(false);

		// om redraw te voorkomen ook toepassen op het drawobject
		// omdat er veel variatie is met de trimming hier een nieuw drawobject toevoegen
		// eerst alles weggooien
		groupObject.clear();

		// dan objecten opnieuw maken
		this.addDrawObjects(null, groupObject, true);

		return { stopPropagation: true };
	}
	onMouseDown(evt, drawObject) {
		let columns = [
			Configuration.CURRENT.columns.getByName(this.id + '_' + Columns.POSITION_TOP),
			Configuration.CURRENT.columns.getByName(this.id + '_' + Columns.POSITION_BOTTOM),
			Configuration.CURRENT.columns.getByName(this.id + '_' + Columns.POSITION_LEFT),
			Configuration.CURRENT.columns.getByName(this.id + '_' + Columns.POSITION_RIGHT),
		];

		columns.forEach((column) => {
			if (column !== null && typeof column !== 'undefined') {
				let drawObject = Canvas.CURRENT.drawObjects.findByDrawId(column.drawObjectId);
				if (drawObject !== null) {
					drawObject.hide();
				}
			}
		});

		// De kolommen opzoeken die gerelateerd zijn zodat deze verborgen kunnen worden
		// bij drag kunnen ze namelijk niet meegenomen worden. Er zijn zoveel variabelen. Verbergen en opnieuw tonen bij loslaten is dan de beste optie

		return { stopPropagation: true };
	}
	onClick(evt, object) {
		Configuration.CURRENT.select({ id: this.id });
		return { stopPropagation: true };
	}

	onChangeMainBeamLength(raster, delta, evt, drawObject) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject) {
		// regels voor kolomgebouwen zijn nogal uitgebreidt daarom bij verslepen niet de trimming laten zien. Dat komt bij het loslaten wel
		let found = false;

		// kijk eerst of een deel van de gebouwkolom in het raster valt
		this.rasters.forEach((currentRaster) => {
			if ((raster.x > -1 && (raster.x === currentRaster.x || raster.x - 1 === currentRaster.x)) || (raster.y > -1 && (raster.y === currentRaster.y || raster.y - 1 === currentRaster.y))) {
				found = true;
			}
		});
		if (found === true) {
			// als een deel van de kolom in het raster valt
			drawObject.drawObjects.forEach((drawObject) => {
				if (typeof drawObject.object !== 'undefined' && drawObject.object !== null && drawObject.object.objectName === 'BuildingColumnTrimmings') {
					drawObject.hide();
				}
			});
		}
	}

	addDrawObjects(canvas, drawGroup, drag) {
		// Check of de hoogte tot de huidige etage groter is dan de hoogte van de gebouwkolom, dan niet tekenen op die etage.
		let activeEtageIndex = Configuration.CURRENT.etages.activeEtageIndex;
		if (this.active === false) {
			// als kolom niet actief ook niet tekenen
			return null;
		}
		if (this.selected === true) {
			if (drag === true) {
				// sizehandle ook aanpassen
				let objectSizeHandle = Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT);
				objectSizeHandle.updateDrawObjects(
					[Math.round(this.x), this.width, Math.round(Configuration.CURRENT.raster.getSizeX() - this.x - this.width)],
					[Math.round(this.y), this.depth, Math.round(Configuration.CURRENT.raster.getSizeY() - this.y - this.depth)],
				);
			} else {
				Configuration.CURRENT.dimensioning.setSizeHandleObject(
					this,
					SizeHandle.TYPE_OBJECT,
					false,
					[Math.round(this.x), this.width, Math.round(Configuration.CURRENT.raster.getSizeX() - this.x - this.width)],
					[Math.round(this.y), this.depth, Math.round(Configuration.CURRENT.raster.getSizeY() - this.y - this.depth)],
				);
			}
		}

		// gebruik de standaardkleur
		let useColor = BuildingColumns.COLORS.notSelected;
		if (this.selected === true) {
			// als kolom geselecteerd afwijkende kleur
			useColor = BuildingColumns.COLORS.selected;
		}
		let saveColor = useColor;
		if (this.hasErrors === true) {
			useColor = BuildingColumns.COLORS.collision;
		}
		if (Configuration.CURRENT.etages.getTotalHeight(activeEtageIndex, true) > this.height) {
			useColor = BuildingColumns.COLORS.notOnEtage;
		}

		if (typeof drawGroup === 'undefined' || drawGroup === null) {
			drawGroup = new ObjectGroup(useColor, null, null, false, this, {});
			this.drawGroupId = drawGroup.id;
		}

		// kolom toevoegen
		drawGroup.push(
			new Rectangle(
				new DrawValue(this.x),
				new DrawValue(this.y),
				new DrawValue(this.width),
				new DrawValue(this.depth),
				useColor,
				null,
				null,
				true,
				this,
				{ type: 'buildingColumn', color: saveColor },
				Configuration.CURRENT.etages.getTotalHeight(activeEtageIndex, true) > this.height,
				Configuration.CURRENT.etages.getTotalHeight(activeEtageIndex, true) > this.height,
			),
		);

		// Bij het tekenen voorkomen dat hij alles tekent maar alleen tekent wat er op de huidige etage zit, daarom geven we etageId mee.
		if (typeof this.buildingColumnTrimmings.addDrawObjects === 'function') {
			// trimmings toevoegen, alleen op etage waar de trimming nodig is, dus daarom etageId meegeven.
			// Trimming weet van zichzelf op welke etage hij zit.
			let activeEtageId = Configuration.CURRENT.etages.activeEtage().id;
			this.buildingColumnTrimmings.addDrawObjects(this, activeEtageId).forEach((drawObject) => {
				drawGroup.push(drawObject);
			});
		}

		Canvas.CURRENT.addDrawObject(drawGroup);
	}
	addDrawObjects3d() {
		this.buildingColumnTrimmings.addDrawObjects3d();
		Canvas3D.CURRENT.addDrawObject(
			new BuildingColumn3D(this.x, this.y, { width: this.width, depth: this.depth, height: this.height, drawY: this.startHeight, ralColor: 7016 }),
			Canvas3D.TYPE_BUILDINGCOLUMN,
		);
	}
	update(object, setTrimming = true) {
		this.x = object.x;
		this.y = object.y;
		this.width = parseInt(object.width);
		this.depth = parseInt(object.depth);
		this.startHeight = parseInt(object.startHeight);

		if (object.customHeight === false) {
			this.updateHeight(Configuration.CURRENT.etages.totalHeight);
		} else {
			this.height = parseInt(object.height);
			this.customHeight = object.customHeight;
		}

		if (typeof object.active === 'undefined' || object.active === null) {
			this.active = true;
		} else {
			this.active = object.active;
		}
		// bij bijvoorbeeld cancel wordt update ook aangeroepen maar als het een nieuw object is moet de trimming niet worden uitgevoerd
		if (setTrimming === true) {
			this.updateTrimming();
		}

		this.addUsedSurface();
	}
	updateTrimming(addColumns = true) {
		this.buildingColumnTrimmings.removeTrimmings();
		let totalHeight = 0;
		Configuration.CURRENT.etages.etages.forEach((etage, index) => {
			// Alleen trimming toevoegen op deze etage als de startHoogte kleiner is dan de huidige etage hoogte en de starthoogte + gebouwkolomhoogte groter is dan de etagehoogte.
			if (this.startHeight < etage.height + totalHeight && this.startHeight + this.height > etage.height + totalHeight) {
				this.buildingColumnTrimmings.setTrimming(this.x, this.y, this.width, this.depth, etage);
			}
			totalHeight += etage.height;
		});

		// Bij update van de trimming vanuit een rasterChanged moeten we de kolommen updaten
		if (addColumns) {
			this.updateColumns();
		}
	}

	updateColumns() {
		let topLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.x, this.y);
		let bottomLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.x, this.y + this.depth);
		let topRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.x + this.width, this.y);
		let bottomRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.x + this.width, this.y + this.depth);

		let columnSize = Columns.COLUMN_SIZE / Configuration.CURRENT.canvas.scaleFactor;

		// Deze allemaal later weghalen, voor oude configuraties die de kolommen nog niet met configurationObjectId werken.
		Configuration.CURRENT.columns.removeByName(this.id);
		Configuration.CURRENT.columns.removeByName(this.id + '_' + Columns.POSITION_TOP);
		Configuration.CURRENT.columns.removeByName(this.id + '_' + Columns.POSITION_BOTTOM);
		Configuration.CURRENT.columns.removeByName(this.id + '_' + Columns.POSITION_LEFT);
		Configuration.CURRENT.columns.removeByName(this.id + '_' + Columns.POSITION_RIGHT);

		this.columnHeights = [];

		const columnsToRemove = Configuration.CURRENT.columns.columns.filter((column) => column.configurationObjectId === this.id);
		columnsToRemove.forEach((column) => {
			Configuration.CURRENT.columns.removeByName(column.name);
		});

		// Voor nieuw aangemaakte kolommen.
		let columns = [];

		let packetHeight = 0;
		let totalEtagesHeight = 0;
		let totalHeight = 0;

		Configuration.CURRENT.etages.etages.forEach((etage, index) => {
			totalEtagesHeight += etage.getHeight(true);

			// Kijken per etage of startHoogte + eigen hoogte groter is dan tot aan de huidig etage in de loop.
			if (this.startHeight + this.height >= totalEtagesHeight) {
				packetHeight = etage.getPacketHeight();
				totalHeight += etage.getHeight(true);

				// TOP COLUMM, aan de bovenkant van de gebouwkolom.
				if (topLeftRaster.x !== topRightRaster.x) {
					// Kan buiten vloer liggen, daarom anders op laatste rasterX zetten.
					const checkTopRightRasterX = topRightRaster.x === -1 ? Configuration.CURRENT.raster.spansX.length - 1 : topRightRaster.x;
					for (let i = topLeftRaster.x; i <= checkTopRightRasterX; i++) {
						const endPointRasterX = Configuration.CURRENT.raster.getSizeX(i);
						const currentRasterActive = etage.isActiveRaster(new RemoveRaster(i, topLeftRaster.y));
						const nextRasterActive = etage.isActiveRaster(new RemoveRaster(i + 1, topRightRaster.y));

						if (
							// Als eindpunt te checken raster na X gebouwkolom ligt.
							// Maar ook of dat voor het einde van het eindpunt ligt.
							endPointRasterX > this.x &&
							endPointRasterX < this.x + this.width &&
							// Als één van de twee rasters niet actief is, extra kolom nodig.
							(currentRasterActive !== nextRasterActive ||
								// Of als linkerboven en rechterboven raster allebei actief zijn maar hoofbalken liggen verticaal. Dan is er doorkruising van de hoofdbalk.
								(currentRasterActive && nextRasterActive && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL))
						) {
							columns.push({
								x: endPointRasterX,
								y: this.y - columnSize / 2,
								name: this.id + '_' + Columns.POSITION_TOP + '_' + i,
							});
						}
					}
				}

				// BOTTOM COLUMM, aan de onderkant van de gebouwkolom.
				if (bottomLeftRaster.x !== bottomRightRaster.x) {
					const checkBottomRightRasterX = bottomRightRaster.x === -1 ? Configuration.CURRENT.raster.spansX.length - 1 : bottomRightRaster.x;
					for (let i = bottomLeftRaster.x; i <= checkBottomRightRasterX; i++) {
						const endPointRasterX = Configuration.CURRENT.raster.getSizeX(i);
						const currentRasterActive = etage.isActiveRaster(new RemoveRaster(i, bottomLeftRaster.y));
						const nextRasterActive = etage.isActiveRaster(new RemoveRaster(i + 1, bottomRightRaster.y));

						if (
							// Als eindpunt te checken raster na X gebouwkolom ligt.
							// Maar ook of dat voor het einde van het eindpunt ligt.
							endPointRasterX > this.x &&
							endPointRasterX < this.x + this.width &&
							// Als één van de twee rasters niet actief is, extra kolom nodig.
							(currentRasterActive !== nextRasterActive ||
								// Of als linksonder en rechtsonder raster allebei actief zijn maar hoofbalken liggen verticaal. Dan is er doorkruising van de hoofdbalk.
								(currentRasterActive && nextRasterActive && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL))
						) {
							columns.push({
								x: endPointRasterX,
								y: this.y + this.depth + columnSize / 2,
								name: this.id + '_' + Columns.POSITION_BOTTOM + '_' + i,
							});
						}
					}
				}

				// LINKER COLUMN, Aan de linkerkant van de gebouwkolom.
				if (topLeftRaster.y !== bottomLeftRaster.y) {
					// Kan buiten vloer liggen, daarom anders op laatste rasterY zetten.
					const checkBottomLeftRasterY = bottomLeftRaster.y === -1 ? Configuration.CURRENT.raster.spansY.length - 1 : bottomLeftRaster.y;
					for (let i = topLeftRaster.y; i <= checkBottomLeftRasterY; i++) {
						const endPointRasterY = Configuration.CURRENT.raster.getSizeY(i);
						const currentRasterActive = etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, i));
						const nextRasterActive = etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, i + 1));

						if (
							// Als eindpunt te checken raster na Y gebouwkolom ligt.
							// Maar ook of dat voor het einde van het eindpunt ligt.
							endPointRasterY > this.y &&
							endPointRasterY < this.y + this.depth &&
							// Als één van de twee rasters niet actief is, extra kolom nodig.
							(currentRasterActive !== nextRasterActive ||
								// Of als beide rasters actief maar hoofdbalken liggen horizontaal. Dan is er doorkruising van de hoofdbalk.
								(currentRasterActive && nextRasterActive && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL))
						) {
							columns.push({
								x: this.x - columnSize / 2,
								y: endPointRasterY,
								name: this.id + '_' + Columns.POSITION_LEFT + '_' + i,
							});
						}
					}
				}

				// RECHTER COLUMN, Aan de rechterkant van de gebouwkolom.
				if (topRightRaster.y !== bottomRightRaster.y) {
					const checkBottomRightRasterY = bottomRightRaster.y === -1 ? Configuration.CURRENT.raster.spansY.length - 1 : bottomRightRaster.y;
					for (let i = topRightRaster.y; i <= checkBottomRightRasterY; i++) {
						const endPointRasterY = Configuration.CURRENT.raster.getSizeY(i);
						const currentRasterActive = etage.isActiveRaster(new RemoveRaster(topRightRaster.x, i));
						const nextRasterActive = etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, i + 1));

						if (
							// Als eindpunt te checken raster na Y gebouwkolom ligt.
							// Maar ook of dat voor het einde van het eindpunt ligt.
							endPointRasterY > this.y &&
							endPointRasterY < this.y + this.depth &&
							// Als één van de twee rasters niet actief is, extra kolom nodig.
							(currentRasterActive !== nextRasterActive ||
								// Of als beide rasters actief maar de hoofdbalken liggen horizontaal. Dan is er doorkruising van de hoofdbalk.
								(currentRasterActive && nextRasterActive && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL))
						) {
							columns.push({
								x: this.x + this.width + columnSize / 2,
								y: endPointRasterY,
								name: this.id + '_' + Columns.POSITION_RIGHT + '_' + i,
							});
						}
					}
				}
			}
		});

		// Check is nodig om te kijken of de berekende hoogte wel boven een etage komt, anders hoeven we ook geen kolommen te pushen
		if (totalHeight - packetHeight > 0) {
			this.columnsHeight = totalHeight - packetHeight;
			columns.forEach((column) => {
				Configuration.CURRENT.columns.push(column.x, column.y, column.name, Columns.BUILDING_COLUMN, { x: column.x, y: column.y }, this.id);
			});
		}
	}

	updateHeight(totalHeight) {
		this.height = totalHeight + this.defaultExtraHeight;
	}
	onSizeHandleChangedHorizontal(evt, drawObject, newDimensions, changedDimensionIndex) {
		if (changedDimensionIndex === 0) {
			this.x = newDimensions[changedDimensionIndex];
		} else {
			this.x = Configuration.CURRENT.etages.activeEtage().floor.width - newDimensions[changedDimensionIndex] - this.width;
		}
		this.calculate();
		this.onChange();
	}
	onSizeHandleChangedVertical(evt, drawObject, newDimensions, changedDimensionIndex) {
		if (changedDimensionIndex === 0) {
			this.y = newDimensions[changedDimensionIndex];
		} else {
			this.y = Configuration.CURRENT.etages.activeEtage().floor.length - newDimensions[changedDimensionIndex] - this.depth;
		}
		this.calculate();
		this.onChange();
	}
}
