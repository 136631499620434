import { Canvas } from './canvas';
import { DrawText } from './drawText';
import { SizeHandle } from './sizeHandle';

export class Dimensions {
	objectName = 'Dimensions';

	/* 
	! Properties
	*/

	dimensions = [];
	mousePriority = 10;
	mouseAreaOffset = { x: 10, y: 10 };
	_onChange = null;
	_onChanged = null;
	_movePossible = null;

	push(dimension) {
		this.dimensions.push(dimension);
	}

	/*
	! Mouse Events
	*/

	onMouseUp(evt, drawObject) {
		if (drawObject.objectParams.type === 'sizeHandleEnd') {
			this.onMouseUpSizeHandle(evt, drawObject);
		}
		return { stopPropagation: true };
	}
	onMouseUpSizeHandle(evt, drawObject) {
		let changedDimensionIndex = drawObject.objectParams.position;
		this.onChanged(evt, drawObject, this.dimensions, changedDimensionIndex);
	}

	onMouseMove(evt, drawObject) {
		// Mouse move over a dimension line, make it resize icon.
		if (drawObject.objectParams.type === 'sizeHandleEnd') {
			Canvas.CURRENT.canvas.style.cursor = this.objectName === 'DimensionsHorizontal' ? 'w-resize' : 'ns-resize';
		}
		// When hovering over the value / number of the dimension.
		else if (drawObject instanceof DrawText) {
			Canvas.CURRENT.canvas.style.cursor = 'pointer';
		}
		return { stopPropagation: true };
	}
	onMouseLeave(evt, drawObject) {
		Canvas.CURRENT.canvas.style.cursor = 'default';
		return { stopPropagation: true };
	}
	onMouseDown(evt, drawObject) {
		// zonder mousedown werkt drag niet
		return { stopPropagation: true };
	}
	onMouseDrag(evt, drawObject) {
		return { stopPropagation: true };
	}
	onMouseLeave(evt, drawObject) {
		Canvas.CURRENT.canvas.style.cursor = 'default';
		return { stopPropagation: true };
	}

	/* 
	! Functions
	*/

	clear() {
		this.dimensions = [];
	}
	onChange(evt, drawObject, params) {
		if (typeof this._onChange === 'function') {
			this._onChange(evt, drawObject, params);
		}
	}
	onChanged(evt, drawObject, dimensions, changedDimensionIndex) {
		if (typeof this._onChanged === 'function') {
			this._onChanged(evt, drawObject, dimensions, changedDimensionIndex);
		}
	}
	movePossible(params) {
		if (typeof this._movePossible === 'function') {
			return this._movePossible(params);
		}
		return true;
	}
	getTotalLength() {
		let totalLength = 0;
		this.dimensions.forEach((dimension) => {
			totalLength += dimension;
		});
		return totalLength;
	}
	get length() {
		return this.dimensions.length;
	}
	setById(id, value) {
		if (typeof id !== 'undefined' && id !== null && id < this.dimensions.length) {
			this.dimensions[id] = value;
		}
	}
	get(id) {
		if (typeof id !== 'undefined' && id !== null) {
			if (id < this.dimensions.length) {
				return this.dimensions[id];
			}
			return -1;
		}
		return this.dimensions;
	}

	addDrawObjects(canvas, params) {}

	isSizeHandleResizable(dimension) {
		if (Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT) === null || typeof Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT) === 'undefined') {
			return false;
		}

		// wanneer de parent undefined is, hoeft de onderstaande check niet uitgevoerd te worden
		if (typeof Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent === 'undefined' || Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent === null) {
			return true;
		}

		// typeof stairwell undefined, zorgt ervoor dat een object als bijv. palletgate geen error toont, omdat er geen propperty stairwell is
		// check of de value overeenkomt met width || depth van het betreffende object, als dit het geval is, return false
		return !(
			(typeof Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.stairWell !== 'undefined' &&
				dimension === Math.round(Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.stairWell.depth)) ||
			(typeof Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.stairWell !== 'undefined' &&
				dimension === Math.round(Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.stairWell.width)) ||
			(typeof Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.stairWell === 'undefined' && dimension === Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.width) ||
			(typeof Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.stairWell === 'undefined' && dimension === Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.depth)
		);
	}

	isSizeHandleEndResizable(dimension) {
		if (Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT) === null || typeof Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT) === 'undefined') {
			return false;
		}

		// wanneer de parent undefined is, hoeft de onderstaande check niet uitgevoerd te worden
		if (typeof Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent === 'undefined' || Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent === null) {
			return true;
		}

		// sizeHandleEnd before object size not resizable
		return !(
			dimension === Math.round(Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.startX) ||
			dimension === Math.round(Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.x) ||
			dimension === Math.round(Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.startY) ||
			dimension === Math.round(Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_OBJECT).parent.y)
		);
	}
}
