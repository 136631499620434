import { Canvas } from '../draw/canvas';
import { SizeHandle } from '../draw/sizeHandle';
import { Configuration } from './configuration';
import { Profiles } from './profiles';

export class Dimensioning {
	objectName = 'Dimensioning';

	/*
    ! Properties
  */
	showDimensioningPopup = false;
	clickedDimensionIndex = -1;
	direction = Profiles.MB_HORIZONTAL;

	/* 
  ! Getters and Setters
  */
	get currentEditingValue() {
		if (this.clickedDimensionIndex !== -1) {
			return this.dimensions[this.clickedDimensionIndex];
		}
	}
	get max() {
		if (this.direction === Profiles.MB_HORIZONTAL) {
			return Configuration.CURRENT.etages.activeEtage().floor.width;
		}
		if (this.direction === Profiles.MB_VERTICAL) {
			return Configuration.CURRENT.etages.activeEtage().floor.depth;
		}
	}
	get min() {
		return 0;
	}

	/* 
  ! Functions
  */
	onClickedDimensionItem(clickedDimensionIndex, direction, dimensions, onChange) {
		// When items are party outside floor, let user not adjust it with the component.
		// Drag is always available as an alternative.
		if (dimensions.some((dimNumber) => dimNumber < 0)) {
			this.showDimensioningPopup = false;
			return;
		}

		this.clickedDimensionIndex = clickedDimensionIndex;
		this.direction = direction;
		this.dimensions = dimensions;
		this.onChange = onChange;
		this.showDimensioningPopup = true;
	}
	updateValue(value) {
		const difference = value - this.dimensions[this.clickedDimensionIndex];

		this.dimensions[this.clickedDimensionIndex] = value;

		// For accesoires items:
		// - BuildingColumn
		// - Holes
		// - Stairs
		// - Rasters
		if (this.dimensions.length > 2) {
			// Calculate adjustments for adjacent elements
			if (this.clickedDimensionIndex > 0) {
				// Update the value of the previous dimension
				this.dimensions[this.clickedDimensionIndex - 1] -= difference / 2;
			}
			if (this.clickedDimensionIndex < this.dimensions.length - 1) {
				// Update the value of the next dimension
				this.dimensions[this.clickedDimensionIndex + 1] -= difference / 2;
			}
		}
		// For items where we don't draw 3 dimensions.
		// - Columns
		else if (this.dimensions.length === 2) {
			const otherIndex = this.clickedDimensionIndex === 0 ? 1 : 0;
			this.dimensions[otherIndex] -= difference;
		}

		// Refers back to the sizeHandle events.
		this.onChange(this.dimensions, this.clickedDimensionIndex);
		this.showDimensioningPopup = false;
	}
	cancel() {
		this.showDimensioningPopup = false;
	}

	afterReconstruct() {
		this.showDimensioningPopup = false;
		this.clickedDimensionIndex = -1;
		this.onChange = null;
		this.dimensions = [];
		this.direction = Profiles.MB_HORIZONTAL;
	}

	setSizeHandleObject(configurationObject, sizeHandleType = SizeHandle.TYPE_OBJECT, setParent = false, dimensionsHorizontal, dimensionsVertical) {
		// Update sizehandles.
		Canvas.CURRENT.sizeHandles.get(sizeHandleType).setByArray(dimensionsHorizontal, dimensionsVertical);

		if (setParent === true) {
		}

		let events = {};

		// Check in object for these events.
		if (typeof configurationObject.onSizeHandleChangedHorizontal === 'function') {
			events.onChangedHorizontal = configurationObject.onSizeHandleChangedHorizontal.bind(configurationObject);
		}

		if (typeof configurationObject.onSizeHandleChangedVertical === 'function') {
			events.onChangedVertical = configurationObject.onSizeHandleChangedVertical.bind(configurationObject);
		}

		if (typeof configurationObject.onSizeHandleChangeHorizontal === 'function') {
			events.onChangeHorizontal = configurationObject.onSizeHandleChangeHorizontal.bind(configurationObject);
		}

		if (typeof configurationObject.onSizeHandleChangedVertical === 'function') {
			events.onChangeVertical = configurationObject.onSizeHandleChangedVertical.bind(configurationObject);
		}

		if (typeof configurationObject.checkMovePossible === 'function') {
			events.movePossible = configurationObject.checkMovePossible.bind(configurationObject);
		}

		Canvas.CURRENT.sizeHandles.get(sizeHandleType).registerEvents(events);
	}
}
