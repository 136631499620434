import { Store } from '../data/store';

import { Configuration } from './configuration';

import { Stair } from './stair';
import { Serializer } from './serializer';

import { StairDynamic } from './StairDynamic';
import { Errors } from './errors';
import { Standard } from './standards/standard';
import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D';
import { Canvas3D } from '../draw3d/Canvas3D';
import { LocalAsset3D } from '../draw3d/assets/LocalAsset3D';
import { Columns } from './columns';
import { Canvas } from '../draw/canvas';

export class Stairs {
	static PASSAGE_HEIGHT = 2300; // trap op kunnen zonder hoofd te stoten
	static PASSAGE_WIDTH = 400; // extra breedte in het trapgat om leuning te monteren en ook vast te kunnen houden
	static COLORS = {
		stairWell: 'white',
		stair: 'gray',
		landing: 'gray',
		stairTail: 'gray',
		stairCollisions: 'red',
		possiblePosition: 'gray',
		possiblePositionOutSideSelected: 'green',
		possiblePositionOutSide: 'blue',
		lineDash: [5, 3],
		selected: 'blue',
	};

	static notCompareable = ['id', 'upcoming', 'amount', 'position'];

	static getOppositeUpComing(i) {
		if (i === Stair.UPCOMING_RIGHT) {
			return Stair.UPCOMING_LEFT;
		}
		if (i === Stair.UPCOMING_TOP) {
			return Stair.UPCOMING_BOTTOM;
		}
		if (i === Stair.UPCOMING_LEFT) {
			return Stair.UPCOMING_RIGHT;
		}

		if (i === Stair.UPCOMING_BOTTOM) {
			return Stair.UPCOMING_TOP;
		}
	}

	objectName = 'Stairs';
	showConfigurator = false;
	stairConfiguratorSettings = new Stair();
	stairs = [];
	mousePriority = 10;
	mouseAreaOffset = { x: 10, y: 10 };
	possiblePossitionsVisible = '';
	constructor(onChange, checkCollisions) {
		this._onChange = onChange;
		this._checkCollisions = checkCollisions;
	}
	select(parameters) {
		this.stairs.forEach((stair) => {
			stair.select(parameters);
		});
	}
	positionAgainstEdge(stair) {
		let width = Configuration.CURRENT.etages.floor.width;
		let length = Configuration.CURRENT.etages.floor.length;
		return stair.stairWell.startX + stair.stairWell.width === width || stair.stairWell.startY + stair.stairWell.depth === length || stair.stairWell.startX === 0 || stair.stairWell.startY === 0;
	}
	directionIsHorizontal(stair) {
		return stair.stairWell.width > stair.stairWell.depth;
	}
	get(index) {
		if (typeof index !== 'undefined' && index !== null) {
			return this.stairs[index];
		}
		return this.stairs;
	}
	onClick(evt, drawObject) {
		this.stairConfiguratorSettings.onClickPossiblePosition(evt, drawObject, this.stairConfiguratorSettings, this);
		return { stopPropagation: true };
	}
	onMouseMove(evt, drawObject) {
		this.stairConfiguratorSettings.onMouseMovePossiblePosition(evt, drawObject);
		return { stopPropagation: true };
	}
	onMouseLeave(evt, drawObject) {
		this.stairConfiguratorSettings.onMouseLeavePossiblePosition(evt, drawObject);
		return { stopPropagation: true };
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}
	push(stairConfiguration, params) {
		const referenceParams = { onChange: this.onChange.bind(this), checkCollisions: this._checkCollisions.bind(this), edit: this.edit.bind(this), remove: this.remove.bind(this) };
		this.stairs.push(new StairDynamic(stairConfiguration.place, stairConfiguration, params));
		this.stairs[this.stairs.length - 1].setReferences(referenceParams);
	}
	get length() {
		let count = 0;
		this.stairs.forEach((stair) => {
			if (stair.active === true) {
				count++;
			}
		});
		return count;
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this._checkCollisions = params.checkCollisions;
		this._redraw = params.redraw;
		params.edit = this.edit.bind(this);
		params.remove = this.remove.bind(this);
		this.stairs.forEach((stair) => {
			stair.setReferences(params);
		});
	}
	removeReferences() {
		this._onChange = null;
		this._checkCollisions = null;
		this._redraw = null;
		this.stairs.forEach((stair) => {
			if (typeof stair.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				stair.removeReferences();
			}
		});
	}
	afterReconstruct() {
		this.stairConfiguratorSettings.newStair = true;
		this.showConfigurator = false; // false slaat hij niet altijd op omdat cancel niet een wijziging in de configuratie tot gevolg heeft. Maar bij reconstruct altijd configurator verbergen
	}
	addUsedSurface() {
		this.stairs.forEach((stair, index) => {
			if (typeof stair.addUsedSurface === 'function') {
				stair.addUsedSurface();
			}
		});
	}
	containsStair(raster) {
		let contains = false;
		this.stairs.forEach((stair) => {
			if (stair.inRaster(raster) === true) {
				contains = true;
			}
		});
		return contains;
	}
	onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam); // voor overhang mainbeamlength zelfde als childbeamlength
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam) {
		this.stairConfiguratorSettings.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam);
	}
	collisionCheck() {
		this.stairs.forEach((stair, index) => {
			stair.collisionCheck();
		});
	}
	redraw() {
		if (this._redraw !== null && typeof this._redraw === 'function') {
			this._redraw();
		}
	}
	addDrawObjects(canvas, params) {
		let regenerate = false;
		this.stairs.forEach((stair, index) => {
			let result = stair.addDrawObjects(canvas);
			if (typeof result !== 'undefined' && result !== null) {
				Canvas.CURRENT.addDrawObject(result.stair);
				if (result.regenerate === true) {
					regenerate = true;
				}
			}
		});

		return { regenerate: regenerate };
	}
	addDrawObjects3d(canvas3d, params, raster, posY, etageIndex) {
		this.stairs.forEach((stair, index) => {
			stair.addDrawObjects3d(canvas3d, params, raster, posY, etageIndex);
		});
	}
	create3DAssets() {
		this.stairs.forEach((stair, index) => {
			let stairMaterial = stair.material;

			if (stairMaterial !== null && typeof stairMaterial !== 'undefined') {
				// Trapboom
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('trapboom', stairMaterial.stinger));

				// Kickedge
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('kickedge_french_stair', stairMaterial.kickEdge));

				// Normaal paaltje
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('stair_handrail_pole', stairMaterial.handRailPost));

				// Kindvriendelijk paaltje
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('stair_childfriendly_pole', stairMaterial.childFriendlyHandRailPost));

				// Stairstep toevoegen
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('stairstep', stair.step.articleId));

				// Handrule
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('stair_handrule', stairMaterial.handRail));

				// Finish
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('landingFinish', stairMaterial.landingFinish));
			}
		});

		// Yellownose nog niet in ERP.
		Canvas3D.CURRENT.addAsset(
			new LocalAsset3D('yellownose', null, {
				fallBackData: { depth: 55, height: 55, width: 1000 },
			}),
		);
	}

	findByProfilePosition(coordinates) {
		return this.stairs.filter((stair) => stair.onProfilePosition(coordinates) === true);
	}

	addPossiblePositions(canvas, params) {
		if (typeof this.stairConfiguratorSettings.addPossiblePositions === 'function') {
			this.stairConfiguratorSettings.addPossiblePositions(canvas, params, this);
		}
	}
	onRasterChanged(params) {
		this.stairs.forEach((stair) => {
			stair.onRasterChanged(params);
		});
	}
	calculateAmount(params) {
		this.stairs.forEach((stair) => {
			stair.calculateAmount(params);
		});
	}
	clearStairSettings() {
		this.stairConfiguratorSettings = new Stair();
	}
	createStair(object) {
		this.showConfigurator = false;

		this.stairConfiguratorSettings.oldValues = '';
		if (this.stairConfiguratorSettings.newStair === true) {
			this.stairConfiguratorSettings.width = this.stairConfiguratorSettings.stepWidth;
			this.stairConfiguratorSettings.place = object.activeTab.name; // Outside of inFloor
			this.stairConfiguratorSettings.stairOid = object.stair.stairOid;

			Configuration.CURRENT.setAccessoriesType('stairs', false);
			this.possiblePossitionsVisible = object.activeTab.name;

			Configuration.CURRENT.notification.show('stair.position', null, () => {
				Configuration.CURRENT.setAccessoriesType('');
				this.possiblePossitionsVisible = '';
			});
		} else {
			Configuration.CURRENT.setAccessoriesType('');
		}

		if (this.stairConfiguratorSettings.objectName === 'StairOutSide') {
			// Deze check is nodig voor wanneer we de endlanding uitzetten.
			// Als de trap dan op een hoek staat en niet op dezelfde positie als de trap zelf dan moeten we de trap dezelfde kant op zetten als de positie.
			if (this.stairConfiguratorSettings.endLanding.active === false && this.stairConfiguratorSettings.upComing !== this.stairConfiguratorSettings.position) {
				this.stairConfiguratorSettings.upComing = this.stairConfiguratorSettings.position;
				this.stairConfiguratorSettings.endLanding.upComing = this.stairConfiguratorSettings.position;
			}
		}

		if (this.stairConfiguratorSettings.intermediateLandings.length > 0) {
			this.stairConfiguratorSettings.intermediateLandings.sort();
			// Bij stair in floor is het niet mogelijk dat de eerste landing tegenovergesteld is.
			if (this.objectName === 'StairInFloor') {
				let firstLanding = this.stairConfiguratorSettings.intermediateLandings.get(0);
				if (firstLanding.upComing === this.stairConfiguratorSettings.upComing) {
					this.stairConfiguratorSettings.intermediateLandings.setUpComing(firstLanding, Stair.toOppositeUpComing(this.stairConfiguratorSettings.upComing));
				}
			}
			this.stairConfiguratorSettings.intermediateLandings.get().forEach((landing, index) => {
				let finish = landing.finish;
				let finishName;
				let finishHeight;
				if (finish !== null && typeof finish !== 'undefined' && this.stairConfiguratorSettings.newStair === false) {
					Store.CURRENT.deckingFinishes.getById(landing.finish, (findFinish) => {
						if (findFinish !== null && typeof findFinish !== 'undefined') {
							finish = findFinish.id;
							finishHeight = findFinish.height;
							finishName = findFinish.name;
						}
					});
					landing.finishName = finishName;
					landing.finishHeight = finishHeight;
				}
			});
		}
		this.stairConfiguratorSettings.calculate();
		this.stairConfiguratorSettings.onChange(true);

		// Na submit van edit-stair en endlanding is actief gezet en crossstair ook dan deze updaten.
		this.stairConfiguratorSettings.endLanding.update(
			this.stairConfiguratorSettings.crossStairWell,
			this.stairConfiguratorSettings.position,
			this.stairConfiguratorSettings.upComing,
			this.stairConfiguratorSettings.stepWidth,
			this.stairConfiguratorSettings.intermediateLandings,
		);
	}
	isPossible(spanX, spanY) {
		return true;
	}
	remove(item) {
		let foundIndex = -1;
		this.stairs.forEach((stair, index) => {
			if (stair.id === item.id) {
				foundIndex = index;
				if (typeof stair.removeUsedSurface === 'function') {
					stair.removeUsedSurface();
				}

				let columns = Configuration.CURRENT.columns;
				columns.removeByName(stair.id);
				columns.removeByName(stair.id + '_' + Columns.POSITION_TOP);
				columns.removeByName(stair.id + '_' + Columns.POSITION_BOTTOM);
				columns.removeByName(stair.id + '_' + Columns.POSITION_RIGHT);
				columns.removeByName(stair.id + '_' + Columns.POSITION_LEFT);
			}
		});
		this.stairs.splice(foundIndex, 1);

		this.onChange();
	}
	edit(item) {
		if (this.possiblePossitionsVisible !== '') {
			// om een of andere reden blijven hangen
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.notification.hide();
			this.onChange();
		}
		this.showConfigurator = true;
		this.stairConfiguratorSettings = item;
		this.stairConfiguratorSettings.newStair = false;
		let serializer = new Serializer();
		this.stairConfiguratorSettings.oldValues = serializer.stringify(this.stairConfiguratorSettings);
	}
	collisions(boundaries, self) {
		let collisionsDetect = false;
		let errorResult = [];
		this.stairs.forEach((stair) => {
			let stairResult = stair.collisions(boundaries, self);

			if (stairResult.result === true) {
				collisionsDetect = true;
				stairResult.errors.getAll().forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: errorResult };
	}
	moveProfilePossible(params) {
		return this.stairs.every((stair) => stair.moveProfilePossible(params));
	}
	newStair(place, etageIndex, etageId) {
		if (this.possiblePossitionsVisible !== '') {
			// om een of andere reden blijven hangen
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.notification.hide();
			this.onChange();
		}

		// Bij new stair word erna altijd update functie uitgevoerd in stair.js, dus standaard waarden hier meegeven.
		let fps = Store.CURRENT.countries.getItem(Configuration.CURRENT.countryCode).fallingProtectionStandard;
		let fallingProtectionStandardRules = new Standard(fps);
		const width = Store.CURRENT.stairSteps.getWidthList().some((item) => item.value === fallingProtectionStandardRules.defaultStepWidth()) ? fallingProtectionStandardRules.defaultStepWidth() : '';

		this.showConfigurator = true;

		this.stairConfiguratorSettings = new StairDynamic(place, {
			newStair: true,
			stepWidth: width,
			width: width,
			upComing: 1,
			startHeight: place === Stair.PLACE_INFLOOR ? Configuration.CURRENT.etages.getTotalHeight(Configuration.CURRENT.etages.activeEtageIndex - 1, true) : 0,
			endHeight: Configuration.CURRENT.etages.getTotalHeight(Configuration.CURRENT.etages.activeEtageIndex, true),
			packetHeight: Configuration.CURRENT.etages.activeEtage().getPacketHeight(),
			type: Store.CURRENT.stairs.getDefaultStair().value,
			fallingProtectionStandard: fps,
			disabledUpcomings: [],
			etageId: etageId,
			etageIndex: etageIndex,
			crossStairWell: false,
		});

		this.stairConfiguratorSettings.createMinimalIntermediateLandings();

		let serializer = new Serializer();
		this.stairConfiguratorSettings.oldValues = serializer.stringify(this.stairConfiguratorSettings);
	}
	findByHandrailPosition(coordinates) {
		return this.stairs.filter((s) => s.active === true && s.onHandrailPosition(coordinates) === true);
	}
	checkIfObjectExistsInList(list, objectToCheck) {
		// loop over de items van de list heen
		for (let i = 0; i < list.length; i++) {
			// stair item op basis van de index
			const obj = list[i];
			// boolean die bijhoudt of alle properties values overeenkomen met de huidige stair[index]
			let allPropertiesMatch = true;
			// loop over elk property van het object heen (object to check is de trap die vergeleken worden met de list item)
			for (const property in objectToCheck) {
				// wanneer item niet gecompared mag worden moet hier niet op gecheckt worden. (id, upcoming), dan naar volgend item in de lijst
				if (Stairs.notCompareable.filter((n) => n === property).length > 0) {
					continue;
				}

				// als item een object is, kan het een array zijn. zoals bijv. endlanding || intermediatelandings
				if (typeof objectToCheck[property] === 'object') {
					// wanneer het een array is moeten we over elk item loopen in de onderstaande functie
					if (Array.isArray(objectToCheck[property])) {
						if (!this.arraysMatch(obj[property], objectToCheck[property])) {
							allPropertiesMatch = false;
							break;
						}
					} else {
						// het is een object, dus recursief object checken of deze objecten hetzelfde zijn
						if ((obj[property] !== null && objectToCheck[property] === null) || (obj[property] === null && objectToCheck[property] !== null)) {
							allPropertiesMatch = false;
							break;
						}

						if (!this.checkIfObjectExistsInList([obj[property]], objectToCheck[property]).exist) {
							allPropertiesMatch = false;
							break;
						}
					}
				} else if ((obj === null && objectToCheck !== null) || (objectToCheck === null && obj !== null)) {
					// Wanneer objectType niet gelijk is, dan heeft de ene stair het object wel, het andere niet.
					// Dan matchen de trappen dus ook niet.
					allPropertiesMatch = false;
					break;
				} else if (obj[property] !== objectToCheck[property]) {
					// wanneer het een primitive is, zoals string of number vergelijken of de property van de huidig item in list en objectToCheck gelijk zijn aan elkaar
					allPropertiesMatch = false;
					break;
				}
			}
			if (allPropertiesMatch) {
				// alles hetzelfde, dus bestaat al in de list, geeft stair terug en true dat deze bestaat.
				return { stair: obj, exist: true };
			}
		}

		// bestaat nog niet in de lijst, dus stair null, en exist is false
		return { stair: null, exist: false };
	}

	arraysMatch(arr1, arr2) {
		// check allereerst of de arrays gelijk zijn aan elkaar
		if (arr1.length !== arr2.length) {
			return false;
		}

		// loop over de array heen, is van item in de list, arr2 is item to check
		for (let i = 0; i < arr1.length; i++) {
			if (typeof arr1[i] === 'object') {
				// check hierin of de intermediatelanding niet gelijk is aan elkaar, dan return false. Wordt in de functie checkIfObjectExistsInList allPropertiesMatch op false gezet en wordt deze nieuw toegevoegd aan de lijst.
				if (!this.checkIfObjectExistsInList(arr2, arr1[i]).exist) {
					return false;
				}
			} else if (!arr2.includes(arr1[i])) {
				// check of elementen bestaan in de array
				return false;
			}
		}
		return true;
	}

	getAmount() {
		let amount = { Stairs: [] };

		// loop over de stairs heen
		this.get().forEach((stair) => {
			// voeg de huidige lijst toe en het huidige item in de stairs list
			let checkStair = this.checkIfObjectExistsInList(amount.Stairs, stair.getAmountData());
			// wanneer de trap niet bestaat, voeg hem toe aan de lijst om naar ERP te sturen.
			if (!checkStair.exist) {
				amount.Stairs.push(stair.getAmountData());
			} else {
				// Bestaat al in de lijst en zoek hiervoor de index van de stair op om amount te increasen.
				let index = amount.Stairs.findIndex((obj) => {
					return Object.is(obj, checkStair.stair);
				});

				amount.Stairs[index].amount++;
			}
		});

		return amount;
	}
	cancel() {
		let serializer = new Serializer();
		this.stairConfiguratorSettings.update(serializer.parse(this.stairConfiguratorSettings.oldValues));
	}

	hasErrors() {
		let hasErrors = 0;
		// 'akker' over de errors per stair.
		this.stairs.forEach((stair, index) => {
			let objectHasErrors = stair.hasErrors;
			if (objectHasErrors === true) {
				hasErrors++;
			}
		});
		return hasErrors > 0;
	}
	getErrors() {
		let errors = new Errors();
		this.stairs.forEach((stair, index) => {
			let objectErrors = stair.getErrors();
			if (typeof objectErrors !== 'undefined' && objectErrors !== null) {
				objectErrors.getAll().forEach((error) => {
					error.source = 'Stair';
					error.sourceDescription = window.Vue.$translate('stair.list.title', { index: index + 1 });

					errors.push(error);
				});
			}
		});
		return errors;
	}
}
