import { Mathematic } from '../helpers/mathematic';
import { Data } from './data';
import { Store } from './store';

export class StairSteps extends Data {
	objectName = 'StairSteps';
	static CURRENT = null;
	url = 'StairSteps';
	distinctList = null;
	onListChanged(cacheId) {
		super.onListChanged(cacheId);
		if (cacheId === 'list') {
			this.distinctList = null;
		}
	}
	mapList(list) {
		let distinctWidth = [...new Set(list.map((x) => x.width))].sort(function (a, b) {
			return a - b;
		});

		if (this.distinctList === null) {
			this.distinctList = distinctWidth.map((width) => {
				return { value: width, caption: Store.CURRENT.companies.selectedCompany.measurementSystem.imperial === false ? width : Mathematic.ImperialToInputString(width) };
			});
		}

		return this.distinctList;
	}
	getWidthList(force) {
		// Kijk of lijst al opgehaald is

		if (typeof this._list.list !== 'undefined' && this._list.list !== null && !force) {
			let distinctWidth = [...new Set(this._list.list.map((x) => x.width))].sort(function (a, b) {
				return a - b;
			});

			if (this.distinctList === null) {
				this.distinctList = distinctWidth.map((width) => {
					return { value: width, caption: width };
				});
			}

			return this.distinctList;
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			return this.list(null, null, null, null, () => {
				this.getWidthList();
			});
		} // geen lijst of niet gevonden dan leeg object
		return [];
	}
	getMinAngle(stepType, width) {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let possibleSteps = this._list.list.filter((item) => item.caption === stepType && item.width === width);
			if (possibleSteps.length > 0) {
				return Math.min.apply(
					Math,
					possibleSteps.map(function (o) {
						return o.minDegrees;
					}),
				);
			}
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object

		return -1;
	}
	getMaxAngle(stepType, width) {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let possibleSteps = this._list.list.filter((item) => item.caption === stepType && item.width === width);
			if (possibleSteps.length > 0) {
				return Math.max.apply(
					Math,
					possibleSteps.map(function (o) {
						return o.minDegrees;
					}),
				);
			}
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object

		return -1;
	}
	// Wanneer angle aangepast word maar het huidige breedte item is niet beschikbaar bij deze angle dan een andere selecteren.
	isValidStep(stepArticleId, stepWidth, stepAngle) {
		let item = this._list.list.find((step) => step.articleId === stepArticleId && step.width === stepWidth && stepAngle >= step.minDegrees && stepAngle <= step.maxDegrees);
		if (typeof item === 'undefined' || item === null) {
			return false;
		}
		return true;
	}

	getStepList(width) {
		if (typeof width === 'undefined' || width === '' || width === 0) {
			return [];
		}
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// zoek alle trede op met betreffende breedte
			let widthFiltered = this._list.list.filter(function (stairStep) {
				return stairStep.width === width;
			});

			// filter unieke treden
			let uniqueSteps = [];
			widthFiltered.forEach((step) => {
				if (uniqueSteps.filter((uniqueStep) => uniqueStep.value === step.groupName).length === 0) {
					uniqueSteps.push({ caption: step.caption, value: step.groupName });
				}
			});
			// sorteer op basis van groepnaam
			uniqueSteps.sort((a, b) => (a.groupName > b.groupName ? 1 : -1));

			return uniqueSteps;
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object
		return [];
	}
	getItem(stepType, width, angle) {
		// omdat de hele lijst met list opgehaald wordt hier geen aparte routine of gebruik van get om een item op de server op te halen.
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// zoek betreffende trede op
			let possibleSteps = this._list.list.filter((item) => item.caption === stepType && item.width === width && item.minDegrees <= angle && item.maxDegrees >= angle);
			if (possibleSteps.length > 0) {
				// als gevonden dan teruggeven
				return possibleSteps[0];
			} else {
				// Wanneer geen trede voldoet moeten we er toch 1 gebruiken dus dan pakken we de eerste uit de lijst als deze voldoet aan het type en breedte.
				possibleSteps = this._list.list.filter((item) => item.caption === stepType && item.width === width);
				if (possibleSteps.length > 0) {
					return possibleSteps[0];
				}
			}
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object
		return {
			id: -1,
			caption: '',
			name: '',
			value: '',
			width: '',
			depth: '',
			minDegrees: '',
			maxDegrees: '',
			centerToCenter: '',
			defaultChoice: '',
			riserPossible: false,
			coatingPossible: false,
			yellowNosePossible: false,
		};
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}

		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
}
