import { Configuration } from '../configurator/configuration';
import { Dimensions } from './dimensions';
import { DrawValue } from './drawValue';
import { ObjectGroup } from './objectGroup';
import { DrawText } from './drawText';
import { SizeHandle } from './sizeHandle';
import { Line } from './line';
import { Store } from '../data/store';
import { Mathematic } from '../helpers/mathematic';
import { Canvas } from './canvas';
import { Profiles } from '../configurator/profiles';

export class DimensionsVertical extends Dimensions {
	objectName = 'DimensionsVertical';

	onMouseDrag(evt, drawObject) {
		let changingDimensionIndex = drawObject.objectParams.position;
		const moveY = evt.delta.y / Canvas.CURRENT.scaleFactor;

		// Wanneer we slepen verslepen we een handle tussen 2 dimensions in.
		// Next dimension dan rechts van de hendel, Prev dimension dan links van de hendel.
		let newLengthNextDimension = 0;
		let newLengthPrevDimension = 0;

		if (changingDimensionIndex < this.dimensions.length) {
			newLengthPrevDimension = Math.round(this.dimensions[changingDimensionIndex] + moveY);
		}
		if (changingDimensionIndex + 1 < this.dimensions.length) {
			newLengthNextDimension = Math.round(this.dimensions[changingDimensionIndex + 1] - moveY);
		}
		if (
			this.movePossible({
				x: -1,
				y: changingDimensionIndex + 1,
				newLengthPrevDimension: newLengthPrevDimension,
				newLengthNextDimension: newLengthNextDimension,
			}) === false
		) {
			return;
		}
		console.log(2);

		if (changingDimensionIndex < this.dimensions.length) {
			this.dimensions[changingDimensionIndex] = newLengthPrevDimension;
		}
		if (changingDimensionIndex + 1 < this.dimensions.length) {
			this.dimensions[changingDimensionIndex + 1] = newLengthNextDimension;
		}

		let params = {};
		params.move = moveY;
		params.raster = changingDimensionIndex + 1;

		this.onChange(evt, drawObject, params);
		return { stopPropagation: true };
	}
	addDrawObjects(params) {
		// maattekst
		let posX = params.sizeHandleSpace;

		let posY = 0;
		if (typeof params.startPosition !== 'undefined' && params.startPosition !== null) {
			posY = params.startPosition;
		}
		const sizeHandleGroup = new ObjectGroup(SizeHandle.COLOR, null, null, false, this, { type: 'sizeHandle' });
		this.dimensions.forEach((dimension, index) => {
			const drawGroup = new ObjectGroup(SizeHandle.COLOR, null, null, false, this, { direction: 'vertical', pos: index, type: 'sizeGroup' }); // direction is richting van waarin de sizehandle beweegt.
			drawGroup.push(
				new DrawText(
					new DrawValue(Canvas.CURRENT.min.x, -posX),
					new DrawValue(posY),
					Store.CURRENT.companies.selectedCompany.measurementSystem.imperial === false ? dimension : Mathematic.ImperialToInputString(dimension),
					new DrawValue(dimension),
					null,
					null,
					null,
					null,
					'center',
					true,
					params.resizable === true && this.isSizeHandleResizable(dimension),
					this,
					{
						type: 'text',
						position: index,
					},
				),
			);
			drawGroup.push(
				new Line(
					new DrawValue(Canvas.CURRENT.min.x, -posX + 5),
					new DrawValue(posY),
					new DrawValue(Canvas.CURRENT.min.x, -posX + 5),
					new DrawValue(posY + dimension),
					null,
					null,
					null,
					null,
					null,
					false,
					this,
					{
						type: 'size',
					},
				),
			);
			drawGroup.push(
				new Line(new DrawValue(Canvas.CURRENT.min.x, -posX), new DrawValue(posY), new DrawValue(Canvas.CURRENT.min.x, -posX + 10), new DrawValue(posY), null, null, null, null, null, false, this, {
					type: 'sizeHandleStart',
				}),
			);
			drawGroup.push(
				new Line(
					new DrawValue(Canvas.CURRENT.min.x, -posX),
					new DrawValue(posY + dimension),
					new DrawValue(Canvas.CURRENT.min.x, -posX + 10),
					new DrawValue(posY + dimension),
					null,
					null,
					null,
					null,
					null,
					params.resizable === true && index < this.dimensions.length - 1 && this.isSizeHandleEndResizable(dimension),
					this,
					{
						type: 'sizeHandleEnd',
						position: index,
					},
				),
			);

			sizeHandleGroup.push(drawGroup);

			posY += dimension;
		});
		Canvas.CURRENT.addDrawObject(sizeHandleGroup);

		return sizeHandleGroup;
	}
	onClick(evt, drawObject) {
		const betweenMouseArea = evt.layerX < drawObject.mouseArea.maxX && evt.layerX > drawObject.mouseArea.minX;
		if (drawObject.objectName === 'DrawText' && betweenMouseArea) {
			Configuration.CURRENT.dimensioning.onClickedDimensionItem(drawObject.objectParams.position, Profiles.MB_VERTICAL, this.dimensions, (dimensions, changedDimensionIndex) => {
				this.onChanged(evt, drawObject, dimensions, changedDimensionIndex);
			});
		}

		return { stopPropagation: true };
	}
	updateDrawObjects(groupObjectVertical, dimensions) {
		groupObjectVertical.drawObjects.forEach((drawGroup, index) => {
			let drawText = drawGroup.drawObjects.find((drawobject) => drawobject instanceof DrawText);
			let endPart = drawGroup.drawObjects.find((drawobject) => drawobject.objectParams.type === 'sizeHandleEnd');
			let beginPart = drawGroup.drawObjects.find((drawobject) => drawobject.objectParams.type === 'sizeHandleStart');
			// !! Aanpassen verticale lijntje einde 1e dimentie.
			if (index === 0) {
				if (endPart !== null && typeof endPart !== 'undefined') {
					endPart.y = new DrawValue(dimensions[0]);
					endPart.eindY = new DrawValue(dimensions[0]);
				}
				if (drawText !== null && typeof drawText !== 'undefined') {
					if (dimensions[0] < 0) {
						drawText.hide();
					} else {
						// Wanneer dan weer in het raster gesleept word weer laten zien.
						drawText.show();
					}
					drawText.width = new DrawValue(dimensions[0]);
					drawText.text = dimensions[0];
				}
			}

			// !! Aanpassen verticale lijntjes tussendimenties, dus bij slepen de breedte van het gesleepte element.
			if (index === 1) {
				if (beginPart !== null && typeof beginPart !== 'undefined') {
					beginPart.y = new DrawValue(dimensions[0]);
					beginPart.eindY = new DrawValue(dimensions[0]);
				}
				if (endPart !== null && typeof endPart !== 'undefined') {
					endPart.y = new DrawValue(dimensions[0] + dimensions[1]);
					endPart.eindY = new DrawValue(dimensions[0] + dimensions[1]);
				}
				if (drawText !== null && typeof drawText !== 'undefined') {
					// Bij slepen hoeven we geen drawText van het gesleepte object aan te passen.
					drawText.y = new DrawValue(dimensions[0]);
				}
			}
			// !! Aanpassen verticale lijntje laatste dimentie.
			if (index === 2) {
				if (beginPart !== null && typeof beginPart !== 'undefined') {
					beginPart.y = new DrawValue(dimensions[0] + dimensions[1]);
					beginPart.eindY = new DrawValue(dimensions[0] + dimensions[1]);
				}
				if (drawText !== null && typeof drawText !== 'undefined') {
					if (dimensions[2] < 0) {
						drawText.hide();
					} else {
						// Bij terugslepen dan weer laten zien.
						drawText.show();
					}
					drawText.y = new DrawValue(dimensions[0] + dimensions[1]);
					drawText.width = new DrawValue(dimensions[2]);
					drawText.text = dimensions[2];
				}
			}
		});
	}
}
