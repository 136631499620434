import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { Span } from './span';
import { SpanArray } from './spanArray';
// import { SizeHandles } from './sizeHandles';
import { RemoveRaster } from './removeRaster';
import { Configuration } from './configuration';
import { Profiles } from './profiles';
import { SizeHandleTotal } from '../draw/sizeHandleTotal';
import { Canvas3D } from '../draw3d/Canvas3D';
import { LocalAsset3D } from '../draw3d/assets/LocalAsset3D';
import { DimensionHorizontalTop3D } from '../draw3d/dimensioning/horizontal/dimensionHorizontalTop3D';
import { DimensionVerticalLeft3D } from '../draw3d/dimensioning/vertical/dimensionVerticalLeft3D';
import { DimensionHorizontalBottom3D } from '../draw3d/dimensioning/horizontal/dimensionHorizontalBottom3D';
import { DimensionVerticalRight3D } from '../draw3d/dimensioning/vertical/dimensionVerticalRight3D';
import { Canvas } from '../draw/canvas';
import { SizeHandle } from '../draw/sizeHandle';

export class Raster {
	objectName = 'Raster';
	static BOVEN = { x: 0, y: -1 };

	static ONDER = { x: 0, y: 1 };
	static LINKS = { x: -1, y: 0 };
	static RECHTS = { x: 1, y: 0 };
	static LINKSBOVEN = { x: -1, y: -1 };
	static LINKSONDER = { x: -1, y: 1 };
	static RECHTSBOVEN = { x: 1, y: -1 };
	static RECHTSONDER = { x: 1, y: 1 };
	static MIN_SIZE = 1000;

	static POSITION_TOP = 0;
	static POSITION_BOTTOM = 1;
	static POSITION_LEFT = 2;
	static POSITION_RIGHT = 3;
	static TYPE_MIDDLERASTER = 4;
	static TYPE_BORDERRASTER = 5;

	spansX = new SpanArray(null, this.onChange.bind(this));
	spansY = new SpanArray(null, this.onChange.bind(this));
	mouseAreaOffset = { x: 0, y: 10 };
	mousePriority = 40;
	rasterInterActive = true;
	onChangeSizeHandleXWaiting = null;
	onChangeSizeHandleYWaiting = null;
	onChangeTimeOut = 50;
	_onChange = null;
	onChange(params, force) {
		if (typeof this._onChange === 'function') {
			this._onChange(params, force);
		}
	}
	mouseMoveActive = { x: -1, y: -1 };
	constructor(onChange) {
		this._onChange = onChange;
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this.spansX.setReferences(params);
		this.spansY.setReferences(params);
	}

	removeReferences() {
		this._onChange = null;
		if (typeof this.spansX.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.spansX.removeReferences();
		}
		if (typeof this.spansY.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.spansY.removeReferences();
		}
	}

	createNewConfiguration(data) {
		this.spansX = new SpanArray(null, this.onChange.bind(this));
		this.spansY = new SpanArray(null, this.onChange.bind(this));
		data.spansX.forEach((spansX, indexX) => {
			this.spansX.push(new Span(spansX));
		});
		data.spansY.forEach((spansY, indexX) => {
			this.spansY.push(new Span(spansY));
		});
	}
	getRasterByCoordinate(xCoordinate = null, yCoordinate = null) {
		const raster = { x: -1, y: -1 };
		let lengthX = 0;
		let lengthY = 0;

		if (xCoordinate !== null) {
			this.spansX.spans.forEach((span, index) => {
				// zoek de xcoordinaat
				if (xCoordinate >= lengthX && xCoordinate < lengthX + span.value) {
					// als xcoordinaat binnen raster valt dan onthouden
					raster.x = index;
				}
				lengthX += span.value;
			});
		}

		if (yCoordinate !== null) {
			this.spansY.spans.forEach((span, index) => {
				// zoek de ycoordinaate
				if (yCoordinate >= lengthY && yCoordinate < lengthY + span.value) {
					// als ycoordinaat binnen raster valt dan onthouden
					raster.y = index;
				}
				lengthY += span.value;
			});
		}

		return raster;
	}
	getSizeX(index = this.spansX.length - 1) {
		return this.spansX.getSize(index);
	}
	getSizeY(index = this.spansY.length - 1) {
		return this.spansY.getSize(index);
	}
	getSize(span, index = span.length - 1) {
		return span.getSize(index);
	}

	getRowPositions(x, y) {
		let rowPositions = [];
		let placement = -1;

		let POSITION_TOP = 0;
		let POSITION_BOTTOM = 1;
		let POSITION_LEFT = 2;
		let POSITION_RIGHT = 3;
		let TYPE_MIDDLERASTER = 4;
		let TYPE_BORDERRASTER = 5;

		if (y === 0) {
			// Eerste rij
			placement = TYPE_BORDERRASTER;
			rowPositions.push(POSITION_TOP);
		} else if (y !== 0 && y !== Configuration.CURRENT.raster.spansY.length - 1) {
			// Middelste rij
			placement = TYPE_MIDDLERASTER;
		} else if (y === Configuration.CURRENT.raster.spansY.length - 1) {
			placement = TYPE_BORDERRASTER;
			rowPositions.push(POSITION_BOTTOM);
		}
		if (x === 0) {
			// Eerste kolom op de laatste rij
			rowPositions.push(POSITION_LEFT);
		} else if (x === Configuration.CURRENT.raster.spansX.length - 1) {
			// Laatste op de laatste rij
			rowPositions.push(POSITION_RIGHT);
		}

		return { rowPositions: rowPositions, placement: placement };
	}

	setStep(step) {
		// doorzetten van step. Komt uit configuration
		this.spansX.getSpans().forEach(function (value) {
			value.step = step;
		});
		this.spansY.getSpans().forEach(function (value) {
			value.step = step;
		});
	}
	getMaxOverSpanHorizontal() {
		// Ophalen van de kolommmen
		let columns = Configuration.CURRENT.columns;

		// bijhouden langste lengte
		let maxLength = 0; //

		// doorloop alle rasterrijen
		for (let indexY = 0; indexY <= this.spansY.length; indexY++) {
			// doorgaan kleiner of gelijk aan omdat er een extra rij kolommen is
			let maxLengthRasterRow = 0; // bijhouden voor huidige rij

			// doorloop alle rasterkolommen
			this.spansX.spans.forEach((spanX, indexX) => {
				// lengte van huidige raster
				let length = spanX.value;
				// zoek kolom op
				let column = columns.find(indexX, indexY);
				if (column !== null) {
					// als kolom gevonden
					length -= column.positionOffset.x; // offset eraf halen optellen (of eraf erbij optellen als negatief)
					if (column.positionOffset.x !== 0) {
					}
				}

				// zoek volgende kolom
				let nextColumn = columns.find(indexX + 1, indexY);
				if (nextColumn !== null) {
					// als die bestaat
					length += nextColumn.positionOffset.x; // offset erbij optellen (of eraf halen als negatief)

					if (nextColumn.positionOffset.x !== 0) {
					}
				}
				if (maxLengthRasterRow < length) {
					maxLengthRasterRow = length;
				}
			});
			if (maxLength < maxLengthRasterRow) {
				maxLength = maxLengthRasterRow;
			}
		}
		return Math.round(maxLength);
	}
	getMaxOverSpanVertical() {
		// Ophalen van de kolommmen
		let columns = Configuration.CURRENT.columns;

		// bijhouden langste lengte
		let maxLength = 0; //

		// doorloop alle rasterkolommen
		for (let indexX = 0; indexX <= this.spansX.length; indexX++) {
			let maxLengthRasterColumn = 0; // bijhouden voor huidige kolom

			// doorloop alle rasterrijen
			this.spansY.spans.forEach((spanY, indexY) => {
				// lengte van huidige raster
				let length = spanY.value;
				// zoek kolom op
				let column = columns.find(indexX, indexY);
				if (column !== null) {
					// als kolom gevonden
					length -= column.positionOffset.y; // offset eraf halen optellen (of eraf erbij optellen als negatief)
				}

				// zoek volgende kolom
				let nextColumn = columns.find(indexX, indexY + 1);
				if (nextColumn !== null) {
					// als die bestaat
					length += nextColumn.positionOffset.y; // offset erbij optellen (of eraf halen als negatief)
				}
				if (maxLengthRasterColumn < length) {
					maxLengthRasterColumn = length;
				}
			});
			if (maxLength < maxLengthRasterColumn) {
				maxLength = maxLengthRasterColumn;
			}
		}

		return Math.round(maxLength);
	}

	/*
		!! ON SIZE HANDLE CHANGED FUNCTIONS. AFTER MOUSE DRAG / MOUSEUP EVENT. OR WHEN APPLYING ADJUST DIMENSIONING POPUP.
	*/
	onSizeHandleChangedHorizontal(evt, drawObject, newDimensions, changedDimensionIndex) {
		this.onSizeHandleChanged(evt, newDimensions, changedDimensionIndex, Profiles.MB_HORIZONTAL);
	}
	onSizeHandleChangedVertical(evt, drawObject, newDimensions, changedDimensionIndex) {
		this.onSizeHandleChanged(evt, newDimensions, changedDimensionIndex, Profiles.MB_VERTICAL);
	}
	onSizeHandleChanged(evt, newDimensions, changedDimensionIndex, direction) {
		// Na drag en loslaten van de sizehandle door middel van drag.
		if (evt.type === 'mouseup') {
			// Vorige raster en volgende raster ophalen.
			// Op basis van versleep handle.
			const prevRasterIndex = changedDimensionIndex;
			const nextRasterIndex = changedDimensionIndex + 1;

			let prevRaster;
			let nextRaster;

			if (direction === Profiles.MB_HORIZONTAL) {
				prevRaster = Configuration.CURRENT.raster.spansX.get(prevRasterIndex);
				nextRaster = Configuration.CURRENT.raster.spansX.get(nextRasterIndex);
			} else if (direction === Profiles.MB_VERTICAL) {
				prevRaster = Configuration.CURRENT.raster.spansY.get(prevRasterIndex);
				nextRaster = Configuration.CURRENT.raster.spansY.get(nextRasterIndex);
			}

			const previousRastersTotal = prevRaster.value + nextRaster.value;

			// Afronden op 50mm voor profielen.
			let prevRasterValue = Math.round(newDimensions[prevRasterIndex] / 50) * 50;

			// Volgende raster dan totale - de afgeronde om geheel te behouden.
			let nextRasterValue = previousRastersTotal - prevRasterValue;

			// Dit dan ook afronden op 50.
			nextRasterValue = Math.round(nextRasterValue / 50) * 50;

			// Opnieuw berekenen prevRaster om totale te behouden.
			prevRasterValue = previousRastersTotal - nextRasterValue;

			// Save
			prevRaster.saveChangedValue(prevRasterIndex, direction, prevRasterValue);
			nextRaster.saveChangedValue(nextRasterIndex, direction, nextRasterValue);
		}
		// Else is voor wanneer we vanuit de popup de dimension hebben aangepast voor het raster.
		else {
			const prevRasterIndex = changedDimensionIndex - 1;
			const currentRasterIndex = changedDimensionIndex;
			const nextRasterIndex = changedDimensionIndex + 1;

			let prevRaster;
			let currentRaster;
			let nextRaster;

			if (direction === Profiles.MB_HORIZONTAL) {
				prevRaster = Configuration.CURRENT.raster.spansX.get(prevRasterIndex);
				currentRaster = Configuration.CURRENT.raster.spansX.get(currentRasterIndex);
				nextRaster = Configuration.CURRENT.raster.spansX.get(nextRasterIndex);
			} else if (direction === Profiles.MB_VERTICAL) {
				prevRaster = Configuration.CURRENT.raster.spansY.get(prevRasterIndex);
				currentRaster = Configuration.CURRENT.raster.spansY.get(currentRasterIndex);
				nextRaster = Configuration.CURRENT.raster.spansY.get(nextRasterIndex);
			}

			let prevRasterValue = 0;
			let nextRasterValue = 0;

			// Bepaal de totale breedte van de voorgaande rasters
			const previousRastersTotalWidth = [prevRaster?.value || 0, currentRaster?.value || 0, nextRaster?.value || 0].reduce((acc, val) => acc + val, 0);

			// Eigen aangepaste waarde die is ingevoerd afronden op 50.
			const currentRasterValue = Math.round(newDimensions[currentRasterIndex] / 50) * 50;

			// Wat we nog moeten verdelen.
			const remainingWidth = previousRastersTotalWidth - currentRasterValue;

			// Split the remaining width between prev and next rasters
			// Gebeurd wanneer we een midden raster aanpassen, dus niet meest links of rechtse of onderste of bovenste.
			if (prevRaster && nextRaster) {
				prevRasterValue = Math.round(remainingWidth / 2 / 50) * 50;
				nextRasterValue = remainingWidth - prevRasterValue;
			}
			// Passen het meest linkse of bovenste raster aan.
			else if (!prevRaster) {
				// Als er geen vorig raster is, pas dan alleen het volgende raster aan
				nextRasterValue = Math.round(remainingWidth / 50) * 50;
			}
			// Passen het meest rechter of onderste raster aan.
			else if (!nextRaster) {
				// Als er geen volgend raster is, pas dan alleen het vorige raster aan
				prevRasterValue = Math.round(remainingWidth / 50) * 50;
			}

			if (prevRaster) {
				prevRaster.saveChangedValue(prevRasterIndex, direction, prevRasterValue);
			}
			currentRaster.saveChangedValue(currentRasterIndex, direction, currentRasterValue);
			if (nextRaster) {
				nextRaster.saveChangedValue(nextRasterIndex, direction, nextRasterValue);
			}
		}
	}

	/*
		!! ON SIZE HANDLE CHANGE FUNCTIONS. ON MOUSE DRAG.
	*/
	// Bij slepen van tussenstukje dimension van het raster.
	onSizeHandleChangeHorizontal(evt, drawObject, params) {
		// tijdens drag van sizehandle
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			Canvas.CURRENT.drawObjects.onChangeChildBeamLength({ x: params.raster, y: -1 }, { x: params.move, y: 0 }, evt, drawObject);
		} else {
			Canvas.CURRENT.drawObjects.onChangeMainBeamLength({ x: params.raster, y: -1 }, { x: params.move, y: 0 }, evt, drawObject);
		}
	}

	onSizeHandleChangeVertical(evt, drawObject, params) {
		// tijdens drag van sizehandle
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			Canvas.CURRENT.drawObjects.onChangeMainBeamLength({ x: -1, y: params.raster }, { x: 0, y: params.move }, evt, drawObject);
		} else {
			Canvas.CURRENT.drawObjects.onChangeChildBeamLength({ x: -1, y: params.raster }, { x: 0, y: params.move }, evt, drawObject);
		}
	}
	isActive(raster, removeRasters) {}
	onClick(evt, object) {
		Configuration.CURRENT.select({ id: this.id });
		if (this.rasterInterActive === true) {
			Configuration.CURRENT.etages.activeEtage().toggleRaster(new RemoveRaster(object.objectParams.x, object.objectParams.y));
		}
	}
	onMouseMove(evt, drawObject) {
		drawObject.lineColor = 'green';
	}
	onMouseLeave(evt, drawObject) {
		drawObject.lineColor = 'transparent';
	}
	checkMovePossible(parameters) {
		return Configuration.CURRENT.profiles.moveProfilePossible(parameters);
	}
	addDrawObjects(canvas, param) {
		let events = {
			onChangeHorizontal: this.onSizeHandleChangeHorizontal.bind(this),
			onChangeVertical: this.onSizeHandleChangeVertical.bind(this),
			onChangedHorizontal: this.onSizeHandleChangedHorizontal.bind(this),
			onChangedVertical: this.onSizeHandleChangedVertical.bind(this),
			movePossible: this.checkMovePossible.bind(this),
		};
		// Wanneer sizehandle nog niet gemaakt dan aanmaken
		if (Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_RASTER) === null || typeof Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_RASTER) === 'undefined') {
			let sizeHandleRaster = new SizeHandleTotal(SizeHandle.TYPE_RASTER, 1, true);
			sizeHandleRaster.setByArray(
				this.spansX.spans.map((span) => span.value),
				this.spansY.spans.map((span) => span.value),
			);
			sizeHandleRaster.registerEvents(events);
			Canvas.CURRENT.sizeHandles.push(sizeHandleRaster);
		} else {
			// Anders updaten.
			Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_RASTER).registerEvents(events);
		}

		this.spansX.getSpans().forEach((spanX, indexX) => {
			this.spansY.getSpans().forEach((spanY, indexY) => {
				let raster = new Rectangle(
					new DrawValue(this.getSizeX(indexX - 1)),
					new DrawValue(this.getSizeY(indexY - 1)),
					new DrawValue(spanX.value),
					new DrawValue(spanY.value),
					'transparent',
					null,
					null,
					Configuration.CURRENT.accessoriesType === '',
					this,
					{ x: indexX, y: indexY, type: 'raster' },
				);
				raster.border = true;
				Canvas.CURRENT.addDrawObject(raster);
			});
		});
	}
	// Add font for dimension on floor.
	create3DAssets() {
		Canvas3D.CURRENT.addAsset(new LocalAsset3D('roboto', null, null, 'font', 'json'));
	}

	addDrawObjects3d() {
		let positionMargin = 450;
		let drawFromCurrentRaster;
		let drawTop;
		let drawBottom;
		let drawRight;
		let drawLeft;

		this.spansX.getSpans().forEach((spanX, indexX) => {
			this.spansY.getSpans().forEach((spanY, indexY) => {
				if (Configuration.CURRENT.drawDimensioning3DEachRaster === true) {
					drawFromCurrentRaster = false;
					drawTop = true;
					drawBottom = true;
					drawLeft = true;
					drawRight = true;
					// Per etage en rasterIndexX en IndexY kijken of het raster boven het huidige actief is,
					// Als dat op 1 van de etagelevels is, dan hoeft het niet aan de bovenkant getekend te worden.
					// Zo ook voor de andere kanten.
					Configuration.CURRENT.etages.etages.forEach((etage, index) => {
						// Als raster op 1 van de etages actief is, dan mag er vanuit het raster getekend worden.
						// Dan afhankelijk van omliggende rasters.
						if (etage.isActiveRaster(new RemoveRaster(indexX, indexY))) {
							drawFromCurrentRaster = true;
						}
						if (etage.isActiveRaster(new RemoveRaster(indexX, indexY - 1))) {
							drawTop = false;
						}
						if (etage.isActiveRaster(new RemoveRaster(indexX, indexY + 1))) {
							drawBottom = false;
						}
						if (etage.isActiveRaster(new RemoveRaster(indexX - 1, indexY))) {
							drawLeft = false;
						}
						if (etage.isActiveRaster(new RemoveRaster(indexX + 1, indexY))) {
							drawRight = false;
						}
					});

					if (drawFromCurrentRaster === true) {
						if (drawTop === true) {
							Canvas3D.CURRENT.addDrawObject(
								new DimensionHorizontalTop3D(this.getSizeX(indexX - 1), 0, this.getSizeY(indexY - 1) - positionMargin, { text: spanX.value, width: spanX.value, indexX: indexX, indexY: indexY }),
								Canvas3D.TYPE_DIMENSIONING,
							);
						}
						if (drawBottom === true) {
							Canvas3D.CURRENT.addDrawObject(
								new DimensionHorizontalBottom3D(this.getSizeX(indexX - 1), 0, this.getSizeY(indexY) + positionMargin, {
									text: spanX.value,
									width: spanX.value,
									indexX: indexX,
									indexY: indexY,
								}),
								Canvas3D.TYPE_DIMENSIONING,
							);
						}

						if (drawLeft === true) {
							Canvas3D.CURRENT.addDrawObject(
								new DimensionVerticalLeft3D(this.getSizeX(indexX - 1) - positionMargin, 0, this.getSizeY(indexY - 1), {
									text: spanY.value,
									width: spanY.value,
									indexX: indexX,
									indexY: indexY,
								}),
								Canvas3D.TYPE_DIMENSIONING,
							);
						}
						if (drawRight === true) {
							Canvas3D.CURRENT.addDrawObject(
								new DimensionVerticalRight3D(this.getSizeX(indexX) + positionMargin, 0, this.getSizeY(indexY - 1), {
									text: spanY.value,
									width: spanY.value,
									indexX: indexX,
									indexY: indexY,
								}),
								Canvas3D.TYPE_DIMENSIONING,
							);
						}
					}
				} else {
					// Horizontale rasters aan de bovenkant van de vloer.
					if (indexY === 0) {
						Canvas3D.CURRENT.addDrawObject(
							new DimensionHorizontalTop3D(this.getSizeX(indexX - 1), 0, this.getSizeY(indexY - 1) - positionMargin, { text: spanX.value, width: spanX.value, indexX: indexX, indexY: indexY }),
							Canvas3D.TYPE_DIMENSIONING,
						);
					}
					// Verticale rasters aan de linkerkant van de vloer.
					if (indexX === 0) {
						Canvas3D.CURRENT.addDrawObject(
							new DimensionVerticalLeft3D(this.getSizeX(indexX - 1) - positionMargin, 0, this.getSizeY(indexY - 1), {
								text: spanY.value,
								width: spanY.value,
								indexX: indexX,
								indexY: indexY,
							}),
							Canvas3D.TYPE_DIMENSIONING,
						);
					}
					// Horizontale rasters aan de onderkant van de vloer.
					if (indexY === this.spansY.length - 1) {
						Canvas3D.CURRENT.addDrawObject(
							new DimensionHorizontalBottom3D(this.getSizeX(indexX - 1), 0, this.getSizeY(indexY) + positionMargin, {
								text: spanX.value,
								width: spanX.value,
								indexX: indexX,
								indexY: indexY,
							}),
							Canvas3D.TYPE_DIMENSIONING,
						);
					}
					if (indexX === this.spansX.length - 1) {
						Canvas3D.CURRENT.addDrawObject(
							new DimensionVerticalRight3D(this.getSizeX(indexX) + positionMargin, 0, this.getSizeY(indexY - 1), {
								text: spanY.value,
								width: spanY.value,
								indexX: indexX,
								indexY: indexY,
							}),
							Canvas3D.TYPE_DIMENSIONING,
						);
					}
				}
			});
		});

		// Sizehandles totaal buitenzijde toevoegen.
		if (Configuration.CURRENT.drawDimensioningAllRasters === true) {
			const totalLengthX = this.spansX.getSize() + Configuration.CURRENT.overhang.size * 2;
			const totalLengthY = this.spansY.getSize() + Configuration.CURRENT.overhang.size * 2;

			// Startpunt van deze sizeHandles is gewoon RasterXY 0 en daar dan de Marge af en één keer de overhang.
			Canvas3D.CURRENT.addDrawObject(
				new DimensionHorizontalTop3D(this.getSizeX(-1) - Configuration.CURRENT.overhang.size, 0, this.getSizeY(-1) - positionMargin * 3, {
					text: totalLengthX,
					width: totalLengthX,
					indexX: 0,
					indexY: 0,
				}),
				Canvas3D.TYPE_DIMENSIONING,
			);

			Canvas3D.CURRENT.addDrawObject(
				new DimensionHorizontalBottom3D(this.getSizeX(-1) - Configuration.CURRENT.overhang.size, 0, this.getSizeY() + positionMargin * 3, {
					text: totalLengthX,
					width: totalLengthX,
					indexX: 0,
					indexY: 0,
				}),
				Canvas3D.TYPE_DIMENSIONING,
			);

			Canvas3D.CURRENT.addDrawObject(
				new DimensionVerticalLeft3D(this.getSizeX(-1) - positionMargin * 3, 0, this.getSizeY(-1) - Configuration.CURRENT.overhang.size, {
					text: totalLengthY,
					width: totalLengthY,
					indexX: 0,
					indexY: 0,
				}),
				Canvas3D.TYPE_DIMENSIONING,
			);

			Canvas3D.CURRENT.addDrawObject(
				new DimensionVerticalRight3D(this.getSizeX() + positionMargin * 3, 0, this.getSizeY(-1) - Configuration.CURRENT.overhang.size, {
					text: totalLengthY,
					width: totalLengthY,
					indexX: 0,
					indexY: 0,
				}),
				Canvas3D.TYPE_DIMENSIONING,
			);
		}
	}

	getMaxSpanvalue(spans) {
		return Math.max.apply(
			Math,
			spans.map(function (span) {
				return span.value;
			}),
		);
	}
}
