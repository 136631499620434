import { DrawValue } from '../draw/drawValue';
import { DrawText } from '../draw/drawText';

import { Rectangle } from '../draw/rectangle';
import { Mathematic } from '../helpers/mathematic';
import { Functions } from '../helpers/functions';

import { Column3D } from '../draw3d/column/Column3D';

import { CapPlateVertical3D } from '../draw3d/capplate/CapPlateVertical3D';
import { CapPlateHorizontal3D } from '../draw3d/capplate/capplateHorizontal3D';

import { BasePlateHorizontal3D } from '../draw3d/baseplate/BasePlateHorizontal3D';
import { BasePlateVertical3D } from '../draw3d/baseplate/BasePlateVertical3D';
import { Canvas3D } from '../draw3d/Canvas3D';
import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D';
import { Raster } from './raster';

import { Columns } from './columns';
import { Configuration } from './configuration';
import { ColumnProtectors } from './columnProtectors';
import { CustomError } from './CustomError';
import { Errors } from './errors';
import { Profiles } from './profiles';
import { Store } from '../data/store';
import { Canvas } from '../draw/canvas';
import { SizeHandle } from '../draw/sizeHandle';
export class Column {
	objectName = 'Column';
	fallBackName = 'column';
	fallBackData = { width: 90, depth: 90 };
	x = 0;
	y = 0;
	rowPositions = [];
	mainBeamId = 0;
	childBeamId = 0;
	childBeam = null;
	mainBeam = null;
	_mainbeamheight = 350;
	placement = -1;
	rasters = 0;
	active = [];
	get boundaries() {
		let width = 120;
		if (typeof Configuration.CURRENT.columns.column.width !== 'undefined') {
			width = Configuration.CURRENT.columns.column.width;
		}

		return [
			{
				topLeft: { x: this.getPosition().startX.value, y: this.getPosition().endY.value },
				topRight: { x: this.getPosition().startX.value + width, y: this.getPosition().endY.value },
				bottomLeft: { x: this.getPosition().startX.value, y: this.getPosition().endY.value + width },
				bottomRight: { x: this.getPosition().startX.value + width, y: this.getPosition().endY.value + width },
			},
		];
	}
	set boundaries(value) {}
	_columnActive = true; // de hele kolom kan uitgezet worden of per etage
	get columnActive() {
		// Als kolom uitstaat dan altijd false teruggeven Of als hij overlapt wordt door een gebouwkolom
		if (this._columnActive === false || this.buildingColumnOverlap === true) {
			return false;
		}
		if (this.type === Columns.GENERATED) {
			// Als gegeneerde kolom dan kijken of er wel actieve etages zijn. Zo niet dan niet actief.
			return this.active.some((activeItem) => activeItem === true);
		}

		// Voor andere kolommen property returnen.
		return this._columnActive;
	}
	set columnActive(value) {
		this._columnActive = value;
	}
	get mainbeamHeight() {
		return this._mainbeamheight;
	}

	set mainbeamHeight(value) {
		this._mainbeamheight = value;
	}
	get height() {
		if (this.columnActive === false) {
			return 0;
		}
		let height = 0;

		if (this.type === Columns.BUILDING_COLUMN) {
			// Hoogte opzoeken van de leftColumn in BuildinColumn.
			// Dit hoeft niet per etage omdat de calculate dit al correct bereken.
			height = Configuration.CURRENT.buildingColumns.getColumnHeight(this.configurationObjectId);
		}

		let etages = Configuration.CURRENT.etages.getEtages();
		etages.forEach((etage, index) => {
			// Als type hole column is.
			// Kolom hoogte die bij een gat hoort is altijd tot aan de etage waarop het gat is toegevoegd.
			// Omdat we over etages loopen dan per etage zoeken naar het juiste gat, dat is gelinkt aan name, dus this.name = hole.id.
			// Zodra die gevonden is hoogte van de etage ophalen.
			if (this.type === Columns.HOLE_COLUMN) {
				let checkHoleOnThisEtage = etage.holes.holes.find((hole) => hole.id === this.name);
				// Eerst checken of op de huidige etage deze kolom met het holeObjectId bestaat.
				// Zo niet dan skip, anders lengte opzoeken en toevoegen aan de kolom die bij het gat in de vloer hoort.
				if (checkHoleOnThisEtage !== null && typeof checkHoleOnThisEtage !== 'undefined') {
					let etageIdHole = checkHoleOnThisEtage.etageId;
					let etageIndex = Configuration.CURRENT.etages.getById(etageIdHole).etageIndex;
					height = etageIndex === 0 ? etage.getHeight(false) : Configuration.CURRENT.etages.getTotalHeight(etageIndex, true);
				}
			}

			// als huidige verdieping of de verdieping erboven actief is
			// door index mee te geven kijkt hij ook of huidige etage actief is.
			// of handmatig toegevoegde kolom. Die heeft geen rasters die actief kunnen zijn maar staat los van rasters
			if (
				(this.type !== Columns.GENERATED && this.type !== Columns.BUILDING_COLUMN && this.type !== Columns.STAIR_TRIMMING_COLUMN && this.type !== Columns.HOLE_COLUMN) ||
				this.upstairsActive(index)
			) {
				height += etage.getHeight(etages.length > 1);
			}

			if (this.type === Columns.STAIR_TRIMMING_COLUMN) {
				etage.stairs.stairs.forEach((stair, index) => {
					if (stair.id === this.name.substring(0, this.name.length - 2)) {
						// Stair gevonden, op basis van etage die in de stair opgeslagen staat de hoogte van de etage

						// Wanneer stair in floor op eerste verdieping tot aan de grond, dan altijd getHeight ophalen, met false als in height on top.
						// Als hoogte on top aan staat voor verdieping 1 zal hij dan de hoogte tot aan het pakket geven.
						// Als dat vrije hoogte is geeft hij de gehele hoogte terug.

						// Wanneer dat niet zo is, dan rekenen we altijd vanaf de vloer tot aan huidige etage inclusief alle pakket hoogtes.
						// Bij meerdere verdiepingen tekenen we altijd kolommen naast de vloer, dus dan is pakkethoogte niet intressant.
						height = stair.etageIndex === 0 ? etage.getHeight(false) : Configuration.CURRENT.etages.getTotalHeight(stair.etageIndex, true);
					}
				});
			}
		});
		// this.active.forEach((activeColumn, index) => {
		// 	if (activeColumn === true) {
		// 		height += etages[index].getHeight(etages.length > 1); // haal hoogte op. Als het 1 verdieping is dan hoogte onderkant vloer anders is het doorbouw dus bovenkant vloer
		// 	}
		// });

		height += Configuration.CURRENT.profiles.getMaxProfileHeight();
		height -= this.mainbeamHeight;

		return height;
	}

	get numberOfLevels() {
		if (this.columnActive === false) {
			return 0;
		}
		let numberOflevels = 0;
		let etages = Configuration.CURRENT.etages.getEtages();

		etages.forEach((etage, index) => {
			if (this.active[index]) {
				numberOflevels += 1;
			}
		});

		// wanneer het een buildingColumn- of stairTrimming column is, aantal verdiepingen
		if (this.type === Columns.BUILDING_COLUMN || this.type === Columns.STAIR_TRIMMING_COLUMN) {
			numberOflevels = etages.length;
		}

		return numberOflevels;
	}

	get columnType() {
		// Als er meer dan 1 verdieping is, is het een multilevel
		if (this.active.length > 1) {
			return Columns.TYPE_MULTILEVELCOLUMN;
		} else {
			if (
				((this.rowPositions.includes(Columns.POSITION_TOP) || this.rowPositions.includes(Columns.POSITION_BOTTOM)) && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) ||
				((this.rowPositions.includes(Columns.POSITION_LEFT) || this.rowPositions.includes(Columns.POSITION_RIGHT)) && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL)
			) {
				return Columns.TYPE_BORDER;
			}

			return Columns.TYPE_MIDDLECOLUMN;
		}
	}

	buildingColumnOverlap = false;
	mousePriority = 20;
	columnProtectors = new ColumnProtectors(null, this.onChange.bind(this));
	mouseAreaOffset = { x: 10, y: 10 };

	name = '';
	selected = false;
	found = true;
	pressureType = '';
	pressure = 0;
	pressureWithoutSafetyFactor = 0;
	drawObjectId = '';

	chooserActive = false;
	offset = { x: 0, y: 0 };
	positionOffset = { x: 0, y: 0 };
	position = { x: null, y: null }; // op termijn moeten alle kolommen position gebruiken. Op dit moment alleen die van buildingcolumn
	id = '';
	type = Columns.GENERATED;
	configurationObjectId = -1;
	constructor(x, y, name, type, position, configurationObjectId = -1) {
		if (typeof type === 'undefined' || type === null) {
			type = Columns.GENERATED;
		}
		this.id = Functions.uuidv4();
		this.x = x;
		this.y = y;
		this.name = name;
		this.type = type;
		if (typeof position !== 'undefined' && position !== null) {
			this.position = position;
		}
		this.configurationObjectId = configurationObjectId;
	}
	select(parameters, canvas) {
		if (this.id === parameters.id) {
			this.selected = !this.selected;
			const drawObject = Canvas.CURRENT.drawObjects.findById(this.id);
			if (typeof drawObject !== 'undefined' && drawObject !== null) {
				drawObject.fillColor = Columns.COLOR.selected;
				drawObject.lineColor = Columns.COLOR.selected;
			}
		} else {
			this.selected = false;
		}
	}
	collisions(boundaries, self) {
		if (this.name === self.id) {
			// Als het een kolom is die hoort bij de gebouwkolom dan lijkt er een overlapping maar is het niet zo omdat ze dicht bij elkaar staan
			return { result: false, errors: [], objectName: this.objectName };
		}

		if (this.columnActive === false) {
			// niet actieve kolom dan kan er geen overlapping zijn
			return { result: false, errors: [], objectName: this.objectName };
		}
		// voorlopig ervan uitgaand dat het een vierkant betreft
		let overlap = false;
		let position = { x: this.getPosition().startX.value, y: this.getPosition().endY.value };

		boundaries.forEach((boundary) => {
			if (Mathematic.overlapRectangles(boundary.topLeft, boundary.bottomRight, position, position) === true) {
				overlap = true;
			}
		});
		if (overlap === true) {
			return {
				result: true,
				errors: [new CustomError(window.Vue.$translate('collision.column', this.type === Columns.GENERATED ? { x: this.x, y: this.y } : {}), Errors.ERRORTYPE.collision, this.objectName, this.id)],
			};
		}
		return { result: false, errors: [], objectName: this.objectName };
	}
	upstairsActive(etageIndex = null) {
		// als de kolom niet actief is dan hoeft niet gekeken te worden naar actieve etages want dan is alles false
		if (this.columnActive === false) {
			return false;
		}
		if (etageIndex === null) {
			etageIndex = Configuration.CURRENT.etages.activeEtageIndex;
		}
		let active = false;

		if (etageIndex < this.active.length) {
			for (let i = etageIndex; i < this.active.length; i++) {
				if (this.active[i] === true) {
					active = true;
				}
			}
		}
		return active;
	}
	setTypeAndPosition() {
		this.rowPositions = [];
		this.placement = -1;

		if (this.y === 0) {
			// Eerste rij
			this.placement = Columns.TYPE_BORDER;
			this.rowPositions.push(Columns.POSITION_TOP);
		} else if (this.y !== 0 && this.y !== Configuration.CURRENT.raster.spansY.length) {
			// Middelste rij
			this.placement = Columns.TYPE_MIDDLECOLUMN;
		} else if (this.y === Configuration.CURRENT.raster.spansY.length) {
			this.placement = Columns.TYPE_BORDER;
			this.rowPositions.push(Columns.POSITION_BOTTOM);
		}

		if (this.x === 0) {
			// Eerste kolom op de laatste rij
			this.rowPositions.push(Columns.POSITION_LEFT);
		} else if (this.x === Configuration.CURRENT.raster.spansX.length) {
			// Laatste op de laatste rij
			this.rowPositions.push(Columns.POSITION_RIGHT);
		}
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this.columnProtectors.setReferences({ onChange: this.onChange.bind(this) });
	}
	removeReferences() {
		this._onChange = null;
		if (typeof this.columnProtectors.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.columnProtectors.removeReferences();
		}
	}
	onChange(status = null) {
		if (typeof this._onChange !== 'undefined') {
			this._onChange(status);
		}
	}

	equals(x, y) {
		return x === this.x && y === this.y;
	}
	setActive(etages) {
		if (this.type !== Columns.GENERATED) {
			return;
		}
		this.buildingColumnOverlap = Configuration.CURRENT.buildingColumns.overlap(this.boundaries) === true;

		// bij overlap gebouwkolom valt de kolom weg dus zijn overige checks onnodig
		if (this.buildingColumnOverlap === true) {
			return;
		}

		this.active = [];

		etages.getEtages().forEach((etage, index) => {
			const rasterCoordinate = { x: this.x, y: this.y };
			const raster = etage.isActiveRaster(rasterCoordinate);
			const rasterLinksBoven = etage.isActiveRaster(this.sumCoordinate(rasterCoordinate, Raster.LINKSBOVEN));
			const rasterBoven = etage.isActiveRaster(this.sumCoordinate(rasterCoordinate, Raster.BOVEN));
			const rasterLinks = etage.isActiveRaster(this.sumCoordinate(rasterCoordinate, Raster.LINKS));
			this.active.push(raster === true || rasterLinksBoven === true || rasterLinks === true || rasterBoven === true);
		});
	}

	onChangeMainBeamLength(raster, delta, evt, drawObject) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject) {
		if ((raster.x > -1 && raster.x === this.x) || (raster.y > -1 && raster.y === this.y)) {
			if (this.type === Columns.BUILDING_COLUMN) {
				// regels voor kolomgebouwen zijn nogal uitgebreidt daarom bij verslepen niet de bijbehorende kolommen laten zien. Dat komt bij het loslaten wel
				drawObject.hide();
				return;
			}
			// drawObject is groep van rectangle en text
			drawObject.drawObjects.forEach((object, index) => {
				object.x.value += delta.x;
				object.y.value += delta.y;

				if (object.constructor.name === 'Line') {
					object.eindX.value += delta.x;
					object.eindY.value += delta.y;
				}
			});
		}
	}
	onMouseUp(evt, drawObject) {
		this.onChange();
		return { stopPropagation: true };
	}
	onMouseDown(evt, drawObject) {
		Configuration.CURRENT.select({ id: drawObject.object.id });

		return { stopPropagation: true };
	}
	onSizeHandleChangedHorizontal(evt, drawObject, newDimensions, changedDimensionIndex) {
		this.onSizeHandleChanged(newDimensions, changedDimensionIndex, true);
	}
	onSizeHandleChangedVertical(evt, drawObject, newDimensions, changedDimensionIndex) {
		this.onSizeHandleChanged(newDimensions, changedDimensionIndex, false);
	}
	onSizeHandleChanged(newDimensions, changedDimensionIndex, isHorizontal) {
		const containBracing = Configuration.CURRENT.etages.getEtages().some((etage) => etage.bracings.contains(this));
		if (containBracing === true) {
			return;
		}
		let showError = (message) => {
			Configuration.CURRENT.showColumnError = true;
			Configuration.CURRENT.columnError = new CustomError(window.Vue.$translate(message), Errors.ERRORTYPE.validate, this.objectName, this.objectName);
			return { stopPropagation: true };
		};

		let originalPosition = -1;

		// Maximale waarde die opgeschoven mag worden (25%), zowel voor eerste als laatste kolom.
		// Voor kolommen met X of Y 0 komt daar breedte van raster 0 uit.
		// Voor kolommen met X of Y gelijk aan aantal rasters in X of Y richting van vloer zal dat de laatste rasterbreedte zijn.
		let threshold = -1;

		if (isHorizontal) {
			// Voor x 0 lengte eerste raster ophalen. Anders lengte raster tot vorige kolom.
			threshold = Configuration.CURRENT.raster.spansX.get(this.x === 0 ? 1 : this.x)?.value / 4;
			// Original positie is 0 of anders eind laatste raster (getSizeX)
			originalPosition = this.x === 0 ? 0 : Configuration.CURRENT.raster.getSizeX(this.x);
		} else {
			// Voor y 0 lengte eerste raster ophalen. Anders lengte raster tot vorige kolom.
			threshold = Configuration.CURRENT.raster.spansY.get(this.y === 0 ? 1 : this.y)?.value / 4;
			// Original positie is 0 of anders eind laatste raster (getSizeY)
			originalPosition = this.y === 0 ? 0 : Configuration.CURRENT.raster.getSizeY(this.y);
		}

		if (typeof originalPosition === 'undefined') {
			return showError('column.columnOutsideFloor');
		}

		// Wanneer kolompositie X 0 of Y 0 of als het de laatste kolom in reeks is.
		// Die kolommen mogen we maar 25% opschuiven.
		// Om veiligeheidsredenen.
		if ((this.x === 0 && isHorizontal) || (this.y === 0 && !isHorizontal)) {
			// Wanneer originePositie (0) + nieuwe afstand ingegeven meer is dan 1/4e van de afstand tot volgende kolom dan geven we een foutmelding.
			if (originalPosition + newDimensions[0] > threshold) {
				return showError('column.isCornerOver25Percent');
			}
		} else if ((this.x === Configuration.CURRENT.raster.spansX.length && isHorizontal) || (this.y === Configuration.CURRENT.raster.spansY.length && !isHorizontal)) {
			// Wanneer orginele positie (maxlengte eigen raster) - de nieuwe afstand van de laaste dimensie kleiner is dan 1/4e van de afstand tussen huidige en vorige kolom. Dan geven we een foutmelding.
			if (originalPosition - newDimensions[newDimensions.length - 1] < originalPosition - threshold) {
				return showError('column.isCornerOver25Percent');
			}
		}
		const nextColumnPosition = isHorizontal ? Configuration.CURRENT.columns.getPositionX(this.x + 1, this.y) : Configuration.CURRENT.columns.getPositionY(this.x, this.y + 1);
		const prevColumnPosition = isHorizontal ? Configuration.CURRENT.columns.getPositionX(this.x - 1, this.y) : Configuration.CURRENT.columns.getPositionY(this.x, this.y - 1);

		// Check of nieuwepositie voorbij volgende kolom ligt, of dat nieuwe positie voor vorige kolom ligt.
		if (newDimensions[0] > nextColumnPosition || newDimensions[0] < prevColumnPosition) {
			return showError('column.columnCollisionColumn');
		}

		if (isHorizontal) {
			originalPosition = this.getPosition().startX.value;
		} else {
			originalPosition = this.getPosition().startY.value;
		}

		if (changedDimensionIndex === 0) {
			// Wanneer changedIndex 0 is dan offset bepalen.
			// Eerste opgegeven waarde min de orginele positie.
			// opgegeven: 4500, orginele positie: 5000, += 4500 - 5000 = - 500;
			// opgegeven 5500: orginele positie: 5000, += 5500 - 500 = 500;
			this.positionOffset[isHorizontal ? 'x' : 'y'] += newDimensions[changedDimensionIndex] - originalPosition;
		} else if (changedDimensionIndex === newDimensions.length - 1) {
			this.positionOffset[isHorizontal ? 'x' : 'y'] -= originalPosition - newDimensions[0];
		}

		this.onChange();
		return { stopPropagation: true };
	}
	onMouseDrag(evt, drawObject) {
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			let containBracing = false;
			Configuration.CURRENT.etages.getEtages().forEach((etage) => {
				if (etage.bracings.contains(this, 'x') === true) {
					containBracing = true;
				}
			});
			if (containBracing === true) {
				return; // bij bracing niet verplaatsen moet op 1 lijn staan
			}
			let column = null;

			// bij drawobjecten heb je kolom (=rectangle) en de kolomdruk = drawtext. Zoek kolom op voor bepalen min-/max verschuinving
			drawObject.drawObjects.forEach((drawObject) => {
				if (drawObject.objectName === 'Rectangle') {
					column = drawObject;
				}
			});

			if (column === null) {
				return { stopPropagation: true }; // niet gevonden dan is er iets mis met het object dus maar niets doen
			}

			// bepaal verschuiving en schaal die waarde
			let deltaX = evt.delta.x / Canvas.CURRENT.scaleFactor;
			let scaledWidth = column.width.value / Canvas.CURRENT.scaleFactor;

			// niet buiten de vloer
			// breedte van de kolom meerekenen. Dit is width en dan geschaald
			let positionColumnAfter = Configuration.CURRENT.columns.getPositionX(this.x + 1, this.y);
			let positionColumnBefore = Configuration.CURRENT.columns.getPositionX(this.x - 1, this.y);

			if (this.x === 0 || this.x === Configuration.CURRENT.raster.spansX.length) {
				// begin of eind max 25%
				let original = Configuration.CURRENT.raster.spansX.get(this.x);
				if (this.x === Configuration.CURRENT.raster.spansX.length) {
					original = Configuration.CURRENT.raster.spansX.get(this.x - 1); // buitenste kolom valt buiten array
				}
				if (typeof original === 'undefined') {
					return { stopPropagation: true };
				}
				if (
					column.x.value + deltaX < 0 ||
					column.x.value + deltaX > Configuration.CURRENT.raster.getSizeX() ||
					(this.x === 0 && this.positionOffset.x + deltaX > original.value / 4) ||
					(this.x === Configuration.CURRENT.raster.spansX.length && original.value + this.positionOffset.x + deltaX < original.value - original.value / 4)
				) {
					return { stopPropagation: true };
				}
			} else if (
				column.x.value + deltaX + scaledWidth > Configuration.CURRENT.raster.getSizeX() ||
				column.x.value + deltaX < 0 ||
				column.x.value + deltaX + scaledWidth > Configuration.CURRENT.columns.getPositionX(this.x + 1, this.y) ||
				column.x.value + deltaX < Configuration.CURRENT.columns.getPositionX(this.x - 1, this.y) + scaledWidth ||
				positionColumnAfter - (column.x.value + deltaX + scaledWidth) > Configuration.CURRENT.profiles.maxMainBeam ||
				column.x.value + deltaX + scaledWidth - positionColumnBefore > Configuration.CURRENT.profiles.maxMainBeam
			) {
				// niet buiten de vloer niet over andere kolom en niet overschrijden maxlengte mainbeam
				return { stopPropagation: true };
			}

			// eerst voor het huidige tekenobject verplaatsen (direct zichtbaar)
			drawObject.drawObjects.forEach((drawObject) => {
				drawObject.x.value += deltaX;
			});

			// ook goed in het object zetten zodat bij een rasterchange de kolom ook verplaatst blijft
			this.positionOffset.x += deltaX;

			Canvas.CURRENT.drawObjects.onColumnChange(
				deltaX,
				0,
				evt,
				Canvas.CURRENT,
				{ etages: Configuration.CURRENT.etages, id: this.id },
				column.x.value - positionColumnBefore,
				positionColumnAfter - column.x.value,
			);
			Canvas.CURRENT.sizeHandles
				.get(SizeHandle.TYPE_OBJECT)
				.setByArray(
					[Math.round(column.x.value), Math.round(Configuration.CURRENT.raster.getSizeX() - column.x.value)],
					[Math.round(column.y.value), Math.round(Configuration.CURRENT.raster.getSizeY() - column.y.value)],
				);
		} else {
			let containBracing = false;
			Configuration.CURRENT.etages.getEtages().forEach((etage) => {
				if (etage.bracings.contains(this, 'y') === true) {
					containBracing = true;
				}
			});
			if (containBracing === true) {
				return; // bij bracing niet verplaatsen moet op 1 lijn staan
			}
			let column = null;

			// bij drawobjecten heb je kolom (=rectangle) en de kolomdruk = drawtext. Zoek kolom op voor bepalen min-/max verschuinving
			drawObject.drawObjects.forEach((drawObject) => {
				if (drawObject.objectName === 'Rectangle') {
					column = drawObject;
				}
			});

			if (column === null) {
				return { stopPropagation: true }; // niet gevonden dan is er iets mis met het object dus maar niets doen
			}

			// bepaal verschuiving en schaal die waarde
			let deltaY = evt.delta.y / Canvas.CURRENT.scaleFactor;
			let scaledHeight = column.height.value / Canvas.CURRENT.scaleFactor;

			// niet buiten de vloer
			// breedte van de kolom meerekenen. Dit is width en dan geschaald
			let positionColumnAfter = Configuration.CURRENT.columns.getPositionY(this.x, this.y + 1);
			let positionColumnBefore = Configuration.CURRENT.columns.getPositionY(this.x, this.y - 1);

			if (this.y === 0 || this.y === Configuration.CURRENT.raster.spansY.length) {
				// begin of eind max 25%
				let original = Configuration.CURRENT.raster.spansY.get(this.y);
				if (this.y === Configuration.CURRENT.raster.spansY.length) {
					original = Configuration.CURRENT.raster.spansY.get(this.y - 1); // buitenste kolom valt buiten array
				}
				if (typeof original === 'undefined') {
					return { stopPropagation: true };
				}
				if (
					column.y.value + deltaY < 0 ||
					column.y.value + deltaY > Configuration.CURRENT.raster.getSizeY() ||
					(this.y === 0 && this.positionOffset.y + deltaY > original.value / 4) ||
					(this.y === Configuration.CURRENT.raster.spansY.length && original.value + this.positionOffset.y + deltaY < original.value - original.value / 4)
				) {
					return { stopPropagation: true };
				}
			} else if (
				column.y.value + deltaY + scaledHeight > Configuration.CURRENT.raster.getSizeY() ||
				column.y.value + deltaY < 0 ||
				column.y.value + deltaY + scaledHeight > Configuration.CURRENT.columns.getPositionY(this.x, this.y + 1) ||
				column.y.value + deltaY < Configuration.CURRENT.columns.getPositionY(this.x, this.y - 1) + scaledHeight ||
				positionColumnAfter - (column.y.value + deltaY + scaledHeight) > Configuration.CURRENT.profiles.maxMainBeam ||
				column.y.value + deltaY + scaledHeight - positionColumnBefore > Configuration.CURRENT.profiles.maxMainBeam
			) {
				// niet buiten de vloer niet over andere kolom en niet overschrijden maxlengte mainbeam

				return { stopPropagation: true };
			}

			// eerst voor het huidige tekenobject verplaatsen (direct zichtbaar)
			drawObject.drawObjects.forEach((drawObject) => {
				drawObject.y.value += deltaY;
			});

			// ook goed in het object zetten zodat bij een rasterchange de kolom ook verplaatst blijft
			this.positionOffset.y += deltaY;

			Canvas.CURRENT.drawObjects.onColumnChange(
				0,
				deltaY,
				evt,
				Canvas.CURRENT,
				{ etages: Configuration.CURRENT.etages, id: this.id },
				column.y.value - positionColumnBefore,
				positionColumnAfter - column.y.value,
			);
			Canvas.CURRENT.sizeHandles
				.get(SizeHandle.TYPE_OBJECT)
				.setByArray(
					[Math.round(column.x.value), this.width, Math.round(Configuration.CURRENT.raster.getSizeX() - column.x.value)],
					[Math.round(column.y.value), this.width, Math.round(Configuration.CURRENT.raster.getSizeY() - column.y.value)],
				);
		}

		return { stopPropagation: true };
	}
	onColumnChange(x, y, evt, canvas, params, actualSizeBefore, actualSizeAfter, drawObject) {
		drawObject.drawObjects.forEach((obj) => {
			obj.x.value += x;
			obj.y.value += y;
		});
	}

	setSizeHandleObject(dimensionsHorizontal, dimensionsVertical) {
		Configuration.CURRENT.canvas.sizeHandles.get(SizeHandle.TYPE_OBJECT).setByArray(dimensionsHorizontal, dimensionsVertical);
		let events = { onChangedHorizontal: this.onSizeHandleChangedHorizontal.bind(this), onChangedVertical: this.onSizeHandleChangedVertical.bind(this) };
		Configuration.CURRENT.canvas.sizeHandles.get(SizeHandle.TYPE_OBJECT).registerEvents(events);
	}
	onMouseMove(evt, drawObject) {
		drawObject.fillColor = this.selected === true ? Columns.COLOR.selected : Columns.COLOR.onMove;
		drawObject.lineColor = this.selected === true ? Columns.COLOR.selected : Columns.COLOR.onMove;

		Canvas.CURRENT.canvas.style.cursor = 'move';

		return { stopPropagation: true };
	}
	getPosition() {
		if (this.position.x !== null && this.position.y !== null) {
			// als positie is opgegeven dan die gebruiken
			return {
				startX: new DrawValue(this.position.x, -Columns.COLUMN_SIZE / 2 + this.offset.x),
				startY: new DrawValue(this.position.y, -Columns.COLUMN_SIZE / 2 + this.offset.y),
				width: new DrawValue(Columns.COLUMN_SIZE, 0, true),
				height: new DrawValue(Columns.COLUMN_SIZE, 0, true),
				endX: new DrawValue(this.position.x, this.offset.x + Columns.COLUMN_SIZE / 2),
				endY: new DrawValue(this.position.y, this.offset.y + Columns.COLUMN_SIZE / 2),
				pressureStartX: new DrawValue(this.position.x, this.offset.x + Columns.PRESSUREOFFSET.x),
				pressureStartY: new DrawValue(this.position.y, -Columns.COLUMN_SIZE / 2 + this.offset.y + Columns.PRESSUREOFFSET.y),
				typeStartX: new DrawValue(this.position.x, this.offset.x + Columns.TYPEOFFSET.x),
				typeStartY: new DrawValue(this.position.y, -Columns.COLUMN_SIZE / 2 + this.offset.y - Columns.TYPEOFFSET.y),
			};
		}

		// geen positie dan nog de oude methode van het raster
		const columnX = Configuration.CURRENT.raster.getSizeX(this.x - 1);
		const columnY = Configuration.CURRENT.raster.getSizeY(this.y - 1);

		return {
			startX: new DrawValue(columnX + this.positionOffset.x, -Columns.COLUMN_SIZE / 2 + this.offset.x),
			startY: new DrawValue(columnY + this.positionOffset.y, -Columns.COLUMN_SIZE / 2 + this.offset.y),
			width: new DrawValue(Columns.COLUMN_SIZE, 0, true),
			height: new DrawValue(Columns.COLUMN_SIZE, 0, true),
			endX: new DrawValue(columnX + this.positionOffset.x, this.offset.x + Columns.COLUMN_SIZE / 2),
			endY: new DrawValue(columnY + this.positionOffset.y, this.offset.y + Columns.COLUMN_SIZE / 2),
			pressureStartX: new DrawValue(columnX + this.positionOffset.x, this.offset.x + Columns.PRESSUREOFFSET.x),
			pressureStartY: new DrawValue(columnY + this.positionOffset.y, -Columns.COLUMN_SIZE / 2 + this.offset.y + Columns.PRESSUREOFFSET.y),
			typeStartX: new DrawValue(columnX + this.positionOffset.x, this.offset.x + Columns.TYPEOFFSET.x),
			typeStartY: new DrawValue(columnY + this.positionOffset.y, -Columns.COLUMN_SIZE / 2 + this.offset.y - Columns.TYPEOFFSET.y),
		};
	}

	getPosition3D(column = this) {
		// Om 3D positie te bepalen eerst kolom positie 2D ophalen.
		let position2D = column.getPosition();

		let x = position2D.startX.value;
		let z = position2D.startY.value;

		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		let gussetplateDepth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.profiles.mainBeams[0].gussetPlate.oid, 'depth');
		let gussetplateWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.profiles.mainBeams[0].gussetPlate.oid, 'width');

		let capPlateWidth =
			Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL
				? Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.capPlate.oid, 'width')
				: Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.capPlate.oid, 'depth');

		let isTopColumn = false;
		let isBottomColumn = false;

		let multipleFloors = Configuration.CURRENT.etages.length > 1;

		// Horizontaal
		if (!multipleFloors) {
			if (typeof this.rowPositions !== 'undefined' && this.rowPositions !== null) {
				if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
					// Onderste rij kolommen hele kolom naar boven
					if (this.rowPositions.includes(Columns.POSITION_BOTTOM)) {
						z -= columnWidth;
					}
					// Alle midden kolommen halve kolom naar boven
					if (!this.rowPositions.includes(Columns.POSITION_TOP) && !this.rowPositions.includes(Columns.POSITION_BOTTOM)) {
						z -= columnWidth / 2;
					}

					// Midden kolommen centreren
					if (!this.rowPositions.includes(Columns.POSITION_RIGHT) && !this.rowPositions.includes(Columns.POSITION_LEFT)) {
						x -= columnWidth / 2;
					}

					if (this.rowPositions.includes(Columns.POSITION_RIGHT)) {
						x -= columnWidth + (capPlateWidth - columnWidth) / 2;
						x -= Math.abs(columnWidth - gussetplateWidth) / 2;
					}

					if (this.rowPositions.includes(Columns.POSITION_LEFT)) {
						x += (capPlateWidth - columnWidth) / 2;
						x += Math.abs(columnWidth - gussetplateWidth) / 2;
					}
				}
				// Verticaal
				if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
					// Onderste kolom naar boven
					if (this.rowPositions.includes(Columns.POSITION_BOTTOM)) {
						z -= columnWidth;
						z -= capPlateWidth / 2;
						z += Math.abs(columnWidth - gussetplateWidth) / 2;
					}

					if (this.rowPositions.includes(Columns.POSITION_TOP)) {
						z += capPlateWidth / 2;
						z -= Math.abs(columnWidth - gussetplateWidth) / 2;
					}

					// Middenkolommen halve terug zodat ze precies in het midden komen tussen de 2 profielen in.
					if (!this.rowPositions.includes(Columns.POSITION_LEFT) && !this.rowPositions.includes(Columns.POSITION_RIGHT)) {
						x -= columnWidth / 2;
						z += Math.abs(columnWidth - gussetplateWidth) / 2;
					}

					// Rechter kolom 1 kolombreedte terug
					if (this.rowPositions.includes(Columns.POSITION_RIGHT)) {
						// Laaste kolom op de eerste rij
						x -= columnWidth / 2;
						x -= gussetplateDepth * 2;
					}
				}
			}
		} else {
			if (typeof this.rowPositions !== 'undefined' && this.rowPositions !== null) {
				if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
					if (this.rowPositions.includes(Columns.POSITION_TOP)) {
						isTopColumn = true;
						isBottomColumn = false;
						z += columnWidth / 2;
					}
					if (this.rowPositions.includes(Columns.POSITION_RIGHT)) {
						x -= columnWidth;
					}
					if (this.rowPositions.includes(Columns.POSITION_BOTTOM)) {
						isBottomColumn = true;
						isTopColumn = false;
						// z += columnWidth / 2;
					}
				} else {
					if (this.rowPositions.includes(Columns.POSITION_LEFT)) {
						isTopColumn = true;
					}

					if (this.rowPositions.includes(Columns.POSITION_RIGHT)) {
						isBottomColumn = true;
						x -= columnWidth / 2;
					}

					if (this.rowPositions.includes(Columns.POSITION_BOTTOM)) {
						z -= columnWidth * 1.5;
					}

					if (this.rowPositions.includes(Columns.POSITION_TOP)) {
						z -= columnWidth / 2;
					}
				}
			}
		}

		return { x: x, z: z, isBottomColumn: isBottomColumn, isTopColumn: isTopColumn };
	}
	onMouseLeave(evt, drawObject, canvas) {
		drawObject.fillColor = this.selected === true ? Columns.COLOR.selected : Columns.COLOR.column;
		drawObject.lineColor = this.selected === true ? Columns.COLOR.selected : Columns.COLOR.column;

		Canvas.CURRENT.canvas.style.cursor = 'default';
	}
	sumCoordinate(coordinate1, coordinate2) {
		return { x: coordinate1.x + coordinate2.x, y: coordinate1.y + coordinate2.y };
	}

	addPressure() {
		if (this.upstairsActive() === false) {
			return;
		}
		const position = this.getPosition();

		return new DrawText(
			position.pressureStartX,
			position.pressureStartY,
			Store.CURRENT.companies.selectedCompany.measurementSystem.imperial === false
				? typeof this.pressureWithoutSafetyFactor !== 'undefined'
					? this.pressureWithoutSafetyFactor + 'kN'
					: this.pressure + ' kN'
				: Mathematic.KgM2ToImperial(typeof this.pressureWithoutSafetyFactor !== 'undefined' ? this.pressureWithoutSafetyFactor : this.pressure) + 'psf',
			null,
			Columns.COLOR.pressure,
			null,
			null,
			null,
			'center',
		);
	}
	isActive(etage) {
		return this.active[etage];
	}
	afterReconstruct() {
		if (this.position.x === -1 && this.position.y === -1) {
			// was eerst -1 maar wordt null omdat positie uiteraard ook negatief kan zijn
			this.position.x = null;
			this.position.y = null;
		}
	}
	addDrawObjects() {
		// kijk of er hier boven verdiepingen zijn die actief zijn dan tekenen anders mag hij weggelaten worden (tenminste als het een gegenereerde (=behorend bij raster) kolom is)
		// columnActive is voor zowel gegenereerd als toegevoegd de mogelijkheid om heel de kolom uit te zetten

		if (this.columnActive === false || (this.upstairsActive() === false && this.type === Columns.GENERATED)) {
			return;
		}
		const column = this;
		const drawObjects = [];
		const drawPosition = this.getPosition();

		// Eigen kolom toevoegen aan de drawGroup.
		const drawObjectColumn = new Rectangle(drawPosition.startX, drawPosition.startY, drawPosition.width, drawPosition.height);
		this.drawObjectId = drawObjectColumn.id;
		drawObjects.push(drawObjectColumn);

		if (Configuration.CURRENT.etages.activeEtageIndex === 0) {
			const columnProtectorsToAdd = this.columnProtectors.addDrawObjects(column);
			columnProtectorsToAdd.forEach((object, index) => {
				drawObjects.push(object);
			});
		}
		if (this.selected === true) {
			Configuration.CURRENT.dimensioning.setSizeHandleObject(
				this,
				SizeHandle.TYPE_OBJECT,
				false,
				[Math.round(drawPosition.startX.value), Math.round(Configuration.CURRENT.raster.getSizeX() - drawPosition.startX.value)],
				[Math.round(drawPosition.startY.value), Math.round(Configuration.CURRENT.raster.getSizeY() - drawPosition.startY.value)],
			);
		}
		return drawObjects;
	}
	onClick(evt, object) {
		return { stopPropagation: true };
	}

	addDrawObjects3d(columnInfo, height) {
		if (!this.columnActive) {
			return null;
		}
		const gussetPlateOid = Configuration.CURRENT.profiles.mainBeams[0].gussetPlate.oid;
		const multipleFloors = Configuration.CURRENT.etages.length > 1;
		let gussetplateDepth = Canvas3D.CURRENT.getModelDataByOid(gussetPlateOid, 'depth');
		const mainBeamDirection = Configuration.CURRENT.profiles.mainBeamDirection;
		if (multipleFloors) {
			gussetplateDepth = 10;
		}

		let index = Configuration.CURRENT.raster.getRasterByCoordinate(Configuration.CURRENT.raster.getSizeX(this.x - 1), Configuration.CURRENT.raster.getSizeY(this.y - 1));
		let offset = 0;
		if (!multipleFloors) {
			if (mainBeamDirection === Profiles.MB_HORIZONTAL) {
				offset = index.y * gussetplateDepth;
			} else {
				offset = index.x * gussetplateDepth;
			}
		} else {
			offset = gussetplateDepth;
		}

		const column = { ...this };
		let columnPosition = this.getPosition3D();
		this.columnProtectors.addDrawObjects3d(column, columnInfo, columnPosition);

		columnInfo.placement = this.placement;
		columnInfo.rowPositions = this.rowPositions;
		let mainBeamHeight = Canvas3D.CURRENT.getBeamData(this.mainBeamId, 'height');

		let paramsBasePlate = {
			drawHeight: 0,
			rowPositions: this.rowPositions,
			oid: columnInfo.basePlate.oid,
			assetName: columnInfo.basePlate.name,
			ralColor: Configuration.CURRENT.colors.columns.ralColor,
		};

		let paramsPlates = {
			info: columnInfo,
			yPosition: this.height,
			multipleFloors: multipleFloors,
			mainBeamHeight: mainBeamHeight,
			gussetPlateOid: gussetPlateOid,
			ralColor: Configuration.CURRENT.colors.columns.ralColor,
		};

		if (this.columnActive) {
			if (mainBeamDirection === Profiles.MB_HORIZONTAL) {
				Canvas3D.CURRENT.addDrawObject(
					new Column3D(columnPosition.x, columnPosition.z, {
						basePlate: new BasePlateVertical3D(columnPosition.x, 0, columnPosition.z + offset, paramsBasePlate),
						capPlate: new CapPlateHorizontal3D(columnPosition.x, this.height, columnPosition.z + offset, paramsPlates),
						info: columnInfo,
						multipleFloors: multipleFloors,
						height: height,
						ralColor: Configuration.CURRENT.colors.columns.ralColor,
						columnType: this.type,
						mainBeamId: this.mainBeamId,
						isTopColumn: columnPosition.isTopColumn,
						isBottomColumn: columnPosition.isBottomColumn,
					}),
					Canvas3D.TYPE_COLUMN,
				);
			} else {
				Canvas3D.CURRENT.addDrawObject(
					new Column3D(columnPosition.x + offset, columnPosition.z, {
						basePlate: new BasePlateHorizontal3D(columnPosition.x + offset, 0, columnPosition.z, paramsBasePlate),
						capPlate: new CapPlateVertical3D(columnPosition.x + offset, this.height, columnPosition.z, paramsPlates),
						info: columnInfo,
						multipleFloors: multipleFloors,
						height: height,
						ralColor: Configuration.CURRENT.colors.columns.ralColor,
						columnType: this.type,
						mainBeamId: this.mainBeamId,
						isTopColumn: columnPosition.isTopColumn,
						isBottomColumn: columnPosition.isBottomColumn,
					}),
					Canvas3D.TYPE_COLUMN,
				);
			}
		}
	}
	create3DAssets(canvas3d, columnInfo) {
		this.columnProtectors.create3DAssets(canvas3d, columnInfo);

		// KOKER VAN KOLOM TOEVOEGEN
		canvas3d.addAsset(
			new ModelAsset3D(columnInfo.name, columnInfo.oid, {
				type: 'model',
				app: Canvas3D.CURRENT.app,
			}),
		);

		// GUSSETPLATE VAN KOLOM TOEVOEGEN
		if (this.mainBeamId != null) {
			// Opzoeken van de mainBeam in profiles, die bij kolom hoort
			let mainBeam = Configuration.CURRENT.profiles.mainBeams.find((mainBeam) => mainBeam.oid === this.mainBeamId);
			if (mainBeam !== null && typeof mainBeam !== 'undefined') {
				canvas3d.addAsset(new ModelAsset3D(mainBeam.gussetPlate.name, mainBeam.gussetPlate.oid));
			}
		}

		// CAP PLATE VAN KOLOM TOEVOEGEN
		if (typeof columnInfo.capPlate !== 'undefined' && columnInfo.capPlate !== null) {
			canvas3d.addAsset(new ModelAsset3D(columnInfo.capPlate.name, columnInfo.capPlate.oid));
		}

		// BASE PLATE VAN KOLOM TOEVOEGEN
		if (typeof columnInfo.basePlate !== 'undefined' && columnInfo.basePlate !== null) {
			canvas3d.addAsset(new ModelAsset3D(columnInfo.basePlate.name, columnInfo.basePlate.oid));
		}

		// Fallback baseplate
		// canvas3d.addAsset(new ModelAsset3D('baseplate', null, { type: 'model', fallBackName: 'baseplate', fallBackData: { width: 200 }, app: Canvas3D.CURRENT.app, folder: 'baseplate' }));
	}
}
