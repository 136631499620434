import { SizeHandle } from './sizeHandle';

export class SizeHandleTotal extends SizeHandle {
	objectName = 'SizeHandleTotal';
	referenceHandle = '';
	constructor(id, order, resizable) {
		if (typeof resizable === 'undefined' || resizable === null) {
			resizable = false;
		}
		super(id, order, resizable);
	}
	addDrawObjects(params) {
		super.addDrawObjects(params);
	}
}
