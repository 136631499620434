import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _588516fa = () => interopDefault(import('..\\pages\\configurations\\index.vue' /* webpackChunkName: "pages/configurations/index" */))
const _1cbb03ea = () => interopDefault(import('..\\pages\\configurator\\index.vue' /* webpackChunkName: "pages/configurator/index" */))
const _b94133c0 = () => interopDefault(import('..\\pages\\dashboard\\index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _760b4e8c = () => interopDefault(import('..\\pages\\invoices\\index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _48bf2f2b = () => interopDefault(import('..\\pages\\login\\index.vue' /* webpackChunkName: "pages/login/index" */))
const _1f809fc0 = () => interopDefault(import('..\\pages\\passwordreset\\index.vue' /* webpackChunkName: "pages/passwordreset/index" */))
const _4db80a0a = () => interopDefault(import('..\\pages\\project\\index.vue' /* webpackChunkName: "pages/project/index" */))
const _6cabdea6 = () => interopDefault(import('..\\pages\\projects\\index.vue' /* webpackChunkName: "pages/projects/index" */))
const _3803cf19 = () => interopDefault(import('..\\pages\\quotations\\index.vue' /* webpackChunkName: "pages/quotations/index" */))
const _1652b00c = () => interopDefault(import('..\\pages\\request-new-password\\index.vue' /* webpackChunkName: "pages/request-new-password/index" */))
const _014d1a6b = () => interopDefault(import('..\\pages\\transport\\index.vue' /* webpackChunkName: "pages/transport/index" */))
const _c1fd89de = () => interopDefault(import('..\\pages\\vr-viewer\\index.vue' /* webpackChunkName: "pages/vr-viewer/index" */))
const _a2835592 = () => interopDefault(import('..\\pages\\transport\\delivery\\index.vue' /* webpackChunkName: "pages/transport/delivery/index" */))
const _29af8340 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/configurations",
    component: _588516fa,
    name: "configurations"
  }, {
    path: "/configurator",
    component: _1cbb03ea,
    name: "configurator"
  }, {
    path: "/dashboard",
    component: _b94133c0,
    name: "dashboard"
  }, {
    path: "/invoices",
    component: _760b4e8c,
    name: "invoices"
  }, {
    path: "/login",
    component: _48bf2f2b,
    name: "login"
  }, {
    path: "/passwordreset",
    component: _1f809fc0,
    name: "passwordreset"
  }, {
    path: "/project",
    component: _4db80a0a,
    name: "project"
  }, {
    path: "/projects",
    component: _6cabdea6,
    name: "projects"
  }, {
    path: "/quotations",
    component: _3803cf19,
    name: "quotations"
  }, {
    path: "/request-new-password",
    component: _1652b00c,
    name: "request-new-password"
  }, {
    path: "/transport",
    component: _014d1a6b,
    name: "transport"
  }, {
    path: "/vr-viewer",
    component: _c1fd89de,
    name: "vr-viewer"
  }, {
    path: "/transport/delivery",
    component: _a2835592,
    name: "transport-delivery"
  }, {
    path: "/",
    component: _29af8340,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
